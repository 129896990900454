import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Tab,
  Row,
  Col,
  Button,
  ListGroup,
  Badge,
  Stack,
  Container,
} from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  ref,
  getDatabase,
  push,
  child,
  update,
  onValue,
} from "firebase/database";
import { useAuth } from "../../../context/authContext";
import { TourForm } from "../Touren/TourForm";
import toast from "react-hot-toast";
import { Menu, Item, theme, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import InfoModal from "../../../InfoModal";
import useWindowDimensions from "../../useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";

export const Touren = () => {
  const usersign = <FontAwesomeIcon icon={faUser} />;
  const userssign = <FontAwesomeIcon icon={faUsers} />;
  const navigate = useNavigate();
  const MENU_ID = "menu-id";
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  const { currentUser,isAdmin } = useAuth();
  const [IsnewTour, setIsnewTour] = useState(true);
  const [TourData, SetTourData] = useState({
    tour: null,
    key: null,
  });
  const db = getDatabase();
  const [FID, setFID] = useState("");
  const [showNewTour, setShowNewTour] = useState(false);
  const [showInfoModal, setshowInfoModal] = useState(false);
  const [ConfirmMenu, setConfirmMenu] = useState([]);
  const [loadingTouren, setloadingTouren] = useState([]);
  const [Loading, setLoading] = useState(true);
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function displayMenu(e, props) {
    // console.table(props)
    // pass the item id so the `onClick` on the `Item` has access to it
    show(e, props);
    // show(e, { props: { id: Number(e.currentTarget.id) } });
    // console.table(e)
  }

  useEffect(() => {
    const dbRef = ref(db, `touren/` + FID);
    setLoading(true);
    return onValue(dbRef, (snap) => {
      if (snap.exists()) {
        const data = snap.val();
        const unsort = Object.keys(data).map((k) => ({
          id: k,
          ...data[k],
        }));
        //const resual = unsort.filter((user) => user.islkw === fzart);
        setloadingTouren(unsort);
        setLoading(false);
        return;
      } else {
        setloadingTouren([]);
        setLoading(false);
      }
    });
  }, [Loading, db, FID]);

  useEffect(() => {
    if (currentUser) {
      setFID(currentUser.photoURL);
    }
  }, [currentUser]);

  useEffect(() => {
    if (!isAdmin) {
      navigate(-1)
      
    }
  }, [isAdmin, navigate]);

  function isItemDisabled({ props }) {
    // use the parameters to determine if you want to disable the item or not
    // you get the idea
    // console.table(props);

    return props.isAktiv;
  }
  const handleMenuItemClick = async ({ event, props }) => {
    setConfirmMenu(props);
    switch (event.currentTarget.id) {
      case "remove":
        setshowInfoModal(true);
        break;
      case "assign":
        break;
      case "email":
        //logic to send email
        break;
      case "sponsor":
        //logic to open sponsor page
        break;
      default:
        break;
    }
  };

  const deleteTourbyUID = (tourid) => {
    const updates = {};
    updates["/touren/" + FID + "/" + tourid] = null;
    return update(ref(db), updates);
  };
  const UpdateTour = (data) => {
    //console.log("DATA.KEY => " , data.id);
    const toastId = toast.loading("Bitte Warten Update...");
    const updates = {};
    updates["/touren/" + FID + "/" + data.id] = data;
    update(ref(db), updates)
      .then(() => {
        toast.success("Tour " + data.bezeichnung + " wurde Aktualisiert");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(
          "Es ist ein fehler beim Update aufgeteten\n" +
            errorCode +
            "\n" +
            errorMessage
        );
      })
      .finally(() => {
        toast.dismiss(toastId);
        setShowNewTour(false);
      });
  };

  const SaveNewTour = (data) => {
    const toastId = toast.loading("Bitte Warten Speicher...");
    const newFZKey = push(child(ref(db), "touren/" + FID)).key;
    const updates = {};
    updates["/touren/" + FID + "/" + newFZKey] = data;
    update(ref(db), updates)
      .then((fz) => {
        setShowNewTour(false);
        toast.success(
          "Tour " + data.bezeichnung + " wurde erfolgreich angelegt!"
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(
          "Es ist ein fehler beim Speichern aufgeteten\n" +
            errorCode +
            "\n" +
            errorMessage
        );
      })
      .finally(() => {
        toast.dismiss(toastId);
      });
  };
  const handleConfirmation = async () => {
    const toastId = toast.loading("Bitte warten bin beim Löschen...");
    try {
      await deleteTourbyUID(ConfirmMenu.id);
      toast.success("Tour wurde gelöscht");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      toast.error(
        "Es ist ein fehler beim Löschen aufgeteten\n" +
          errorCode +
          "\n" +
          errorMessage
      );
    } finally {
      setConfirmMenu([]);
      toast.dismiss(toastId);
      setShowNewTour(false);
      setshowInfoModal(false);
    }
  };

  const handleDecline = (e) => {
    e.preventDefault();
    setshowInfoModal(false);
  };

  const childToParent = (e, data) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      toast.error("Es sind nicht alle Felder korrekt ausgefüllt");
    } else {
      e.preventDefault();

      if (!IsnewTour) {
        //UpdateFZ
        UpdateTour(data);
      } else {
        //NewFZ
        SaveNewTour(data);
      }
    }
  };

  const handleClickTour = (data, key) => {
    //setclass(true);
    SetTourData({
      tour: data,
      key: key,
    });
    setIsnewTour(false);
    setShowNewTour(true);
  };

  const handleClick = (e) => {
    e.preventDefault();

    SetTourData({
      user: null,
      key: null,
    });

    setIsnewTour(true);
    setShowNewTour(true);
  };

  return (
    

    <>
      {currentUser ? (
        
        
        
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} lg={3} md={5}>
              {!isMobile || !showNewTour ? (
                <Card className="mt-3 bg-light align-items-stretch myCard">
                  <Card.Header className=" d-flex justify-content-between align-middle ">
                    <div>Touren</div>
                  </Card.Header>
                  <Card.Body className="d-flex flex-column">
                    <Tab.Container id="TESTID">
                      <Row>
                        <Col sm={12}>
                          <ListGroup>
                            <Scrollbars
                              autoHeight
                              autoHeightMax="calc(100vh - 230px)"
                            >
                              {loadingTouren.map((tour) => (
                                <ListGroup.Item
                                  className="my-2 d-inline-block d-flex justify-content-between align-items-center"
                                  key={tour.id}
                                  id={tour.id}
                                  action
                                  onContextMenu={(e) => {
                                    displayMenu(e, {
                                      props: {
                                        id: tour.id,
                                        isAktiv: tour.isAktiv,
                                      },
                                    });
                                  }}
                                  onClick={() => {
                                    handleClickTour(tour, tour.id);
                                  }}
                                >
                                  {`${tour.bezeichnung}`}

                                  <Stack
                                    direction="horizontal"
                                    className="ms-auto"
                                    gap={3}
                                  >
                                    {tour.userassign ? (
                                      <Badge
                                        bg="success"
                                        pill
                                        style={{ width: 150 }}
                                      >
                                        <div className="row d-flex align-items-center">
                                          <div className="col-md-1">
                                            {userssign}
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-xs-6">
                                            {tour.userassign.map((fahrer) => (
                                              <div key={fahrer.value}>
                                                {fahrer.label}
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </Badge>
                                    ) : (
                                      <Badge
                                        bg="danger"
                                        pill
                                        style={{ width: 150 }}
                                      >
                                        {usersign}
                                        <span> </span>
                                      </Badge>
                                    )}
                                    {tour.isAktiv ? (
                                      <Badge bg="success" style={{ width: 60 }}>
                                        Aktiv
                                      </Badge>
                                    ) : (
                                      <Badge bg="danger" style={{ width: 60 }}>
                                        Deaktiv
                                      </Badge>
                                    )}
                                  </Stack>
                                </ListGroup.Item>
                              ))}
                            </Scrollbars>
                          </ListGroup>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Card.Body>
                  <Card.Footer>
                    <div className="d-grid gap-2 mt-auto">
                      <Button variant="outline-success" onClick={handleClick}>
                        Neue Tour hinzufügen
                      </Button>
                    </div>
                  </Card.Footer>
                </Card>
              ) : (
                <></>
              )}
            </Col>
            {showNewTour ? (
              <Col xs={12} sm={12} lg={9} md={8}>
                <TourForm
                  childToParent={childToParent}
                  setShowNewTour={setShowNewTour}
                  formdata={TourData}
                  IsnewTour={IsnewTour}
                />
              </Col>
            ) : (
              <></>
            )}
          </Row>

          <Menu id={MENU_ID} theme={theme.dark}>
            <Item
              id="remove"
              disabled={isItemDisabled}
              hidden={false}
              onClick={handleMenuItemClick}
            >
              Löschen
            </Item>
          </Menu>
          <InfoModal
            bodytext={"Sind Sie sich sicher das Sie die Tour löschen möchten?"}
            titeltext={"Bestätigung zum Löshen"}
            handleok={handleConfirmation}
            onHide={handleDecline}
            show={showInfoModal}
          ></InfoModal>
        </Container>
      ) : (
        navigate("/login")
      )}
    </>
  );
};
