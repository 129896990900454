import React, { useState, useEffect } from "react";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash.isempty";
import { InputGroup, Card, Form, Col, Row, ListGroup, Button, Tabs, Tab, Spinner, Table } from 'react-bootstrap';
import moment from "moment-feiertage";
import { Scrollbars } from 'react-custom-scrollbars-2';
import useWindowDimensions from '../../useMediaQuery.js';







const Anwesheitclickday = ({
  currentEvent
}) => {
  const { currentUser, isAdmin } = useAuth();
  const [FID, setFID] = useState("");
  const navigate = useNavigate();
  const [mydata, SetMyData] = useState(null)
  const [lastTime, SetLastTime] = useState(null)
  const [feierabendzeit, SetFeierabendzeit] = useState(null)
  const { height, width } = useWindowDimensions();
  const isMobile = width < 768;

  const formatTime = (timestamp) => {
    return new Intl.DateTimeFormat('de-DE', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp);
  }
  const milltoHHMMSS = (start, feierabend) => {



    const startDate = moment(start);

   // console.log(startDate)

    const timeEnd = moment(feierabend);
    const diff = timeEnd.diff(startDate);
    const diffDuration = moment.duration(diff);




    var secends = diffDuration.seconds();
    var minutes = diffDuration.minutes();
    var hours = Math.trunc(diffDuration.asHours());
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (hours < 10) {
      hours = '0' + hours;
    }

    if (secends < 10) {
      secends = '0' + secends;
    }
    return hours + ':' + minutes + ':' + secends

  }

  useEffect(() => {
    if (currentUser) {
      setFID(currentUser.photoURL);
    }
  }, [currentUser, navigate]);
  useEffect(() => {
    if (!isAdmin) {
      navigate("/Dashbord", { replace: true });
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    console.log("objVal=>", currentEvent)
    if (currentEvent) {
      const objVal = Object.values(currentEvent)
   
      SetMyData(objVal)
      if (typeof objVal[0].places !== "undefined") {
        var lastT = Number(Object.keys(objVal[0].places['Feierabend Zone']).pop())
        SetLastTime(lastT)
       // console.log("typeof=>", typeof objVal[0].end)
        if (typeof objVal[0].end === "undefined") {
          objVal[0].end = lastT
          SetFeierabendzeit(lastT)
        } else {
          SetFeierabendzeit(objVal[0].end)
        }
      }

    }
  }, [currentEvent]);

  useEffect(() => {
    if (mydata) {
    //  console.log(mydata)
    }
  }, [mydata]);









  const getData = () =>{
    return (
      <>
      <Row className="mb-3 ">
      <Form.Group as={Col} md="6" >
        <Form.Label>Stopps laut Scanner</Form.Label>
        <Form.Control
          type="text"
          disabled
          value={mydata[0].SLS}
        />
      </Form.Group>

      <Form.Group as={Col} md="6" >
        <Form.Label>Tour</Form.Label>
        <Form.Control
          type="text"
          disabled
          value={mydata[0].Tour}
        />
      </Form.Group>
    </Row>
    <Row className="mb-3 ">
      <Form.Group as={Col} md="4"  >
        <Form.Label >Fahrzeug</Form.Label>
        <Form.Control
          type="text"
          disabled
          value={mydata[0].Fahrzeug.Kennzeichen}
        />
      </Form.Group>
      <Form.Group as={Col} md="4"  >
        <Form.Label >Spesen</Form.Label>
        <Form.Control
          type="text"
          disabled
          isInvalid = {!mydata[0].spesen}
          isValid  = {mydata[0].spesen}
          value={mydata[0].spesen ? "Ja" : "Nein"}
        />
      </Form.Group>
      <Form.Group as={Col} md="2"  >
        <Form.Label >Tagesabschluss</Form.Label>
        <Form.Control
          type="text"
          disabled
          isInvalid = {!mydata[0].tagesabschluss}
          isValid  = {mydata[0].tagesabschluss}
          value={mydata[0].tagesabschluss ? "Ja" : "Nein"}
        />
      </Form.Group>
      <Form.Group as={Col} md="2"  >
        <Form.Label >Parkplatz</Form.Label>
        <Form.Control
          type="text"
          disabled
          isInvalid = {!mydata[0].zonefeierabend}
          isValid  = {mydata[0].zonefeierabend}
          value={mydata[0].zonefeierabend ? "Ja" : "Nein"}
        />
      </Form.Group>
    </Row>
    <Row className="mb-3 ">
      <Form.Group as={Col} md="4"  >
        <Form.Label >Arbeitsbeginn</Form.Label>
        <Form.Control
          type="text"
          disabled
          value={formatTime(mydata[0].beginn)}
        />
      </Form.Group>
      <Form.Group as={Col} md="4"  >
        <Form.Label >Feierabend</Form.Label>
        <Form.Control
          type="text"
          disabled
          value={formatTime(mydata[0].end)}
        />
      </Form.Group>
      <Form.Group as={Col} md="4"  >
        <Form.Label >Arbeitszeit</Form.Label>
        <Form.Control
          type="text"
          disabled
          value={milltoHHMMSS(mydata[0].beginn, mydata[0].end)}
        />
      </Form.Group>

    </Row>
    <Row className="mb-3 ">
      <Form.Group as={Col} md="6"  >
        <Form.Label >Geladene FP</Form.Label>
        <Form.Control
          type="text"
          disabled
          value={typeof mydata[0].paletten === 'undefined' ? '' : mydata[0].paletten.FP.geladen}
        />
      </Form.Group>
      <Form.Group as={Col} md="6"  >
        <Form.Label >Abgegebene FP</Form.Label>
        <Form.Control
          type="text"
          disabled
          value={typeof mydata[0].paletten === 'undefined' ? '' : mydata[0].paletten.FP.entladen}
        />
      </Form.Group>

    </Row>
    <Row className="mb-3 ">

      <Form.Group as={Col} md="6"  >
        {typeof mydata[0].places !== 'undefined' ? (
          <>
            <ListGroup>

              <Form.Label >Parkplatz</Form.Label>
              <Scrollbars autoHeight autoHeightMax={350}>
                {Object.entries(mydata[0].places['Feierabend Zone']).map(([key, value]) => (
                  <ListGroup.Item
                    key={key}
                  >
                    {`${formatTime(key) + " " + value}`}
                  </ListGroup.Item>
                ))}
              </Scrollbars>

            </ListGroup>
            <Form.Label className="mt-3" >Aufenhalt Parkplatz bis Feierabend</Form.Label>
            {
              " " + milltoHHMMSS(lastTime, feierabendzeit)
            }
          </>
        ) : (
          <></>
        )}

      </Form.Group>

      <Form.Group as={Col} md="6"  >
        <ListGroup>
          {typeof mydata[0].places !== 'undefined' ? (
            <>
              <Form.Label >Lade Endlade Stelle</Form.Label>
              <Scrollbars
                autoHeight
                autoHeightMax={400}
              >
                {Object.entries(mydata[0].places['Lade-Entlade Punkt']).map(([key, value]) => (
                  <ListGroup.Item
                    key={key}
                  >
                    {`${formatTime(key) + " " + value}`}
                  </ListGroup.Item>
                ))}
              </Scrollbars>
            </>
          ) : (
            <></>
          )}
        </ListGroup>

      </Form.Group>

    </Row>
    </>
    )

  }










  return (
    <>
      {mydata !== null ? (
        <div>
          {isMobile ? 
            <div className="text-center text-white bg-dark font-weight-bold" > Für weitere Infomationen benzutzen Sie ein Desktop</div> 
            : 
            getData()
          }


         
        


        </div>
      ) : (<></>)}

    </>

  )









};
export default Anwesheitclickday;