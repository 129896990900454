import admin from "firebase/compat/app";
import "firebase/compat/auth";






const app = admin.initializeApp({
  apiKey: 'AIzaSyDTY2xjdPNolbtxZGNC2uJmtw4sw6NKuWI',
  authDomain: 'livestopps-60539.firebaseapp.com',
  databaseURL: "https://livestopps-60539-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "livestopps-60539",
  storageBucket: "livestopps-60539.appspot.com",
  messagingSenderId: "320622675908",
  appId: "1:320622675908:web:e8a0a8274106781f1c4691",
  measurementId: "G-82GGF7LXJF"
});




export const auth = app.auth();

export default app;


