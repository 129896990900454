import React, { useState, useEffect } from "react";
import "../../../App.css";
import { useNavigate } from "react-router-dom"
import { 
  Card,
  Tab,
  Row,
  Col,
  Button,
  ListGroup,
  Badge,
  Stack,
  Container,
} from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import Switch from "react-switch";
import {
  ref,
  getDatabase,
  push,
  child,
  update, 
  onValue,
} from "firebase/database";
import { useAuth } from "../../../context/authContext";
import { FZForm } from "../Fahrzeuge/FZForm";
import toast from "react-hot-toast";
import { Menu, Item, theme, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import InfoModal from "../../../InfoModal";
import useWindowDimensions from "../../useMediaQuery";


export const Fahrzeuge = () => {
  const navigate = useNavigate();
  const MENU_ID = "menu-id";
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  
  const {  currentUser,isAdmin } = useAuth();
  const [IsnewFZ, setIsnewFZ] = useState(true);
  const [FZData, SetFZData] = useState({
    FZ: null,
    key: null,
  });
  const db = getDatabase();
  const [FID, setFID] = useState("");
  const [fzart, setfzart] = useState(true);
  const [showInfoModal, setshowInfoModal] = useState(false); 
  const [ConfirmMenu, setConfirmMenu] = useState([]);
  const [SortFZ, setSortFZ] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [showNewFZ, setShowNewFZ] = useState(false);
  const { show } = useContextMenu({
    id: MENU_ID,
  });
  const handleChange = (nextChecked) => {
    //setShowNewUser(false);
    setfzart(nextChecked);
  };

  function displayMenu(e, props) {
    // console.table(props)
    // pass the item id so the `onClick` on the `Item` has access to it
    show(e, props);
    // show(e, { props: { id: Number(e.currentTarget.id) } });
    // console.table(e)
  }
  function isItemDisabled({ props }) {
    // use the parameters to determine if you want to disable the item or not
    // you get the idea
    // console.table(props);

    return props.isAktiv;
  }
  const handleMenuItemClick = async ({ event, props }) => {
    setConfirmMenu(props);
    switch (event.currentTarget.id) {
      case "remove":
        setshowInfoModal(true);
        break;
      case "assign":
       
        break;
      case "email":
        //logic to send email
        break;
      case "sponsor":
        //logic to open sponsor page
        break;
      default:
        break;
    }
  };
 
  useEffect(() => {
    if (!isAdmin) {
      navigate(-1)
      
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    if (currentUser) {
      setFID(currentUser.photoURL);
    }
  }, [currentUser]);

  useEffect(() => {
    const dbRef = ref(db, `fahrzeuge/` + FID);
    return onValue(dbRef, (snap) => {
      if (snap.exists()) {
        const data = snap.val();
        const unsort = Object.keys(data).map((k) => ({
          id: k,
          ...data[k],
        }));
        const resual = unsort.filter((user) => user.islkw === fzart);
        setSortFZ(resual);
        setLoading(false);

        return;
      }else{
        setSortFZ([])
      }

      //setLoading(true)
    });
  }, [fzart, Loading, db, FID]);

  const deleteFZbyUID = (fzid) => {
    const updates = {};
    updates["/fahrzeuge/" + FID + "/" + fzid] = null;
    updates["/mhd/" + FID + "/" + fzid] = null;
    return update(ref(db), updates);
  };

  const handleConfirmation = async () => {
    const toastId = toast.loading("Bitte warten bin beim Löschen...");
    try {
      await deleteFZbyUID(ConfirmMenu.id);
      toast.success("Fahrzeug wurde gelöscht");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      toast.error(
        "Es ist ein fehler beim Löschen aufgeteten\n" +
          errorCode +
          "\n" +
          errorMessage
      );
    } finally {
      setConfirmMenu([]);
      toast.dismiss(toastId);
      setShowNewFZ(false);
      setshowInfoModal(false);
    }
  };

 
  const handleDecline = (e) => {   
    e.preventDefault();
    setshowInfoModal(false);
  };

 

  const childToParent = (e, data, mhd) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      toast.error("Es sind nicht alle Felder korrekt ausgefüllt");
    } else {
      e.preventDefault();

      if (!IsnewFZ) {
        //UpdateFZ
        UpdateFZ(data, mhd);
      } else {
        //NewFZ
        SaveNewFZ(data, mhd);
      }
    }
  };
  const UpdateFZ = (data, mhd) => {
    //console.log("DATA.KEY => " , data.id);
    const toastId = toast.loading("Bitte Warten Update...");
    const updates = {};
    updates["/fahrzeuge/" + FID + "/" + data.id] = data;
    updates["/mhd/" + FID + "/" + data.id] = mhd;
    update(ref(db), updates)
      .then(() => {
        toast.success(data.kennzeichen + " wurde Aktualisiert");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(
          "Es ist ein fehler beim Update aufgeteten\n" +
            errorCode +
            "\n" +
            errorMessage
        );
      })
      .finally(() => {
        toast.dismiss(toastId);
        setShowNewFZ(false);
      });
  };

  const SaveNewFZ = (data, mhd) => {
    const toastId = toast.loading("Bitte Warten Speicher...");
    const newFZKey = push(child(ref(db), "fahrzeuge/" + FID)).key;
    const updates = {};
    updates["/fahrzeuge/" + FID + "/" + newFZKey] = data;
    updates["/mhd/" + FID + "/" + newFZKey] = mhd;
    update(ref(db), updates)
      .then((fz) => {
        setShowNewFZ(false);
        toast.success(data.kennzeichen + " wurde erfolgreich angelegt!");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(
          "Es ist ein fehler beim Speichern aufgeteten\n" +
            errorCode +
            "\n" +
            errorMessage
        );
      })
      .finally(() => {
        toast.dismiss(toastId);
      });
  };

  const handleClickFZ = (data, key) => {
    //setclass(true);
    SetFZData({
      fz: data,
      key: key,
    });
    setIsnewFZ(false);
    setShowNewFZ(true);
  };

  const handleClick = (e) => {
    e.preventDefault();

    SetFZData({
      user: null,
      key: null,
    });
    
    setIsnewFZ(true);
    setShowNewFZ(true);
  };

  return (
    <>
     {currentUser ? (
      <Container fluid>
      
        <Row>
          <Col xs={12} sm={12} lg={3} md={4}>
          {!isMobile || !showNewFZ ? (
            <Card className="mt-3 bg-light align-items-stretch myCard">
              <Card.Header className=" d-flex justify-content-between align-middle ">
                <div>Fahrzeuge  </div>
                <div>
                  <label>
                    <Switch
                      onChange={handleChange}
                      checked={fzart}
                      offColor="#f0a000"
                      onColor="#00a000"
                      className="react-switch align-middle text-right"
                      uncheckedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 12,
                            color: "black",
                            paddingRight: 2,
                          }}
                        >
                          PKW
                        </div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 12,
                            color: "black",
                            paddingRight: 2,
                          }}
                        >
                          LKW
                        </div>
                      }
                    />
                  </label>
                </div>
              </Card.Header>
              <Card.Body className="d-flex flex-column">
                <Tab.Container id="TESTID">
                  <Row>
                    <Col sm={12}>
                      <ListGroup>
                        <Scrollbars
                          autoHeight
                          autoHeightMax="calc(100vh - 230px)"
                        >
                          {SortFZ.map((fz) => (
                            <ListGroup.Item
                              className="my-2 d-inline-block d-flex justify-content-between align-items-start"
                              key={fz.id}
                              id={fz.id}
                              action
                              onContextMenu={(e) => {
                                displayMenu(e, {
                                  props: {
                                    id: fz.id,
                                    isAktiv: fz.isAktiv,
                                  },
                                });
                              }}
                              onClick={() => {
                                handleClickFZ(fz, fz.id);
                              }}
                            >
                              {`${fz.kennzeichen}`}

                              <Stack
                                direction="horizontal"
                                className="ms-auto"
                                gap={3}
                              >
                                {fz.isAktiv ? (
                                  <Badge bg="success" pill>
                                    Aktiv
                                  </Badge>
                                ) : (
                                  <Badge bg="danger" pill>
                                    Deaktiv
                                  </Badge>
                                )}
                                {fz.isleihfahrzeug ? (
                                  <Badge bg="info" pill>
                                    Leihfahrzeug
                                  </Badge>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                            </ListGroup.Item>
                          ))}
                        </Scrollbars>
                      </ListGroup>
                    </Col>
                  </Row>
                </Tab.Container>
              </Card.Body>
              <Card.Footer>
                <div className="d-grid gap-2 mt-auto">
                  <Button variant="outline-success" onClick={handleClick}>
                    Neues Fahrzeug hinzufügen
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          ):(<></>)}  
          </Col>
          {showNewFZ ? (
            <Col xs={12} sm={12} lg={9} md={8}>
              <FZForm
                childToParent={childToParent}
                setShowNewFZ={setShowNewFZ}
                formdata={FZData}
                IsnewFZ={IsnewFZ}
              />
            </Col>
          ) : (
            <></>
          )}
        </Row>
      
      
      <Menu id={MENU_ID} theme={theme.dark}>
        <Item
          id="remove"
          disabled={isItemDisabled}
          hidden={false}
          onClick={handleMenuItemClick}
        >
          Löschen
        </Item>
    
      </Menu>
      <InfoModal
        bodytext={"Sind Sie sich sicher das Sie das Fahzeug löschen möchten?"}
        titeltext={"Bestätigung zum Löshen"}
        handleok={handleConfirmation}
        onHide={handleDecline}
        show={showInfoModal}
      ></InfoModal>
      </Container>

     ):(
      navigate("/login")
    )}   
    
    </>
  );
};
