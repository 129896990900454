import React from 'react';
//import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import { AuthProvider }from "../src/context/authContext"

const container = document.getElementById('root');
const root =  ReactDOMClient.createRoot(container);


/*ReactDOM.render(
    <AuthProvider> 
    <App />,
    document.getElementById('root')
    </AuthProvider>
  );*/

root.render(
  
        <AuthProvider>      

            <App />
        </AuthProvider>
    
);

