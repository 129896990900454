import React, {  useState ,useEffect} from "react";

import {  ref, getDatabase, update } from "firebase/database";
import { auth } from "../../firebase";
import { updateProfile } from "firebase/auth";
import { set as set1 } from "date-fns";
import {
  Form,
  Card,
  Button,
  Alert,
  Container,
  Col,
  Navbar,
  Row,
  InputGroup,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";

function Register() {
  const GetDate = (hour, minutes) => {
    return set1(new Date(), {
      seconds: 0,
      hours: hour,
      milliseconds: 0,
      minutes: minutes,
    });
  };

  const cardStyle = {
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: "8px",
    boxShadow: "0 0 15px 1px rgba(0, 0, 0, 0.4)",
  };

  
  const preStylegreen = {
    backgroundColor: "rgb(223,240,216)",      
 };
 const preStylered = {
  backgroundColor: "rgb(242,222,222)",     
};

  const [validated, setValidated] = useState(false);
  
  const navigate = useNavigate();
  const { singup } = useAuth();
  const [passwort, setPasswort] = useState(null);
  const [error, setError] = useState("");
  const [loding, setLoding] = useState(false);
  const [registerData, setRegisterData] = useState({
    aktiveDate: new Date().getTime(),
    createDate: new Date().getTime(),
    isAktiv: true,
    isDelete: false,
    firmenname: "",
    ansprechpartner: "",
    email: "",
    telefon: "",
    strasse: "",
    plz: "",
    ort: "",
  });
  const [registerDataUser, setRegisterDataUser] = useState({
    color: "#697689",
    aktiveDate: new Date().getTime(),
    createDate: new Date().getTime(),
    fid: "",
    isAktiv: true,
    isLohnabrechnungen: false,
    isSpesen: false,
    isDelete: false,
    name: "",
    email: "",
    telefon: "",
    strasse: "",
    plz: "",
    ort: "",
    rollen: [{ value: 1, label: "Admin" }],
  });
  const arbeitzeiten = {
    arbeitsbeginn: GetDate(6, 0),
    arbeitsende: GetDate(14, 30),
  };
  const mhd = {
    Fahrerkarteauslesen: "",
    Firmeneintritt: "",
    Geburtstag: "",
    ADR: "",
    Fahrerkarte: "",
    BKrFQG: "",
    Führerschein: "",
  };


  useEffect(() => {
   
  },[validated]);


  const handleSubmit = async (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      try {
        const dbRef = ref(getDatabase());
        setLoding(true);
        setError("");
        await singup(registerData.email, passwort).then(
          async (firebaseUser) => {
            const newuserid = firebaseUser.user.uid;
            console.log("singup =>", newuserid);
            updateProfile(firebaseUser.user, {
              photoURL: newuserid,
              displayName: registerData.ansprechpartner,
            }).then(async function () {
              registerDataUser.fid = newuserid;
              const updates = {};
              updates["/firmen/" + newuserid] = registerData;
              updates["/users/" + newuserid + "/" + newuserid] =
                registerDataUser;
              updates["/mhd/" + newuserid + "/" + newuserid] = mhd;
              updates["/arbeitzeiten/" + newuserid + "/" + newuserid] = {
                arbeitsbeginn: arbeitzeiten.arbeitsbeginn.getTime(),
                arbeitsende: arbeitzeiten.arbeitsende.getTime(),
              };
              await update(dbRef, updates).then(function () {
                console.log("update updated! =>", newuserid);
                auth.signOut();
                navigate("/login");
              });
            });
          }
        );
      } catch (error) {
        setError(error.message);
      } finally {
        setLoding(false);
      }
    }
    setValidated(true);
  };

  return (
    <>
      <Navbar variant="dark" bg="dark">
        <Container>
          <Navbar.Brand className="mx-auto" style={{ fontSize: 22 }}>
            Service-Subunternehmer
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container fluid>
        <Col xs={12} sm={12} md={6} lg={5} className="mx-auto">
          <Card className="mt-3 bg-light align-items-stretch" style={cardStyle}>
            <Card.Body>
              <h2 className="text-center mb-4">Firma registrieren</h2>
              {error && (
                <Alert variant="danger">
                  <Alert.Heading className="text-center">
                    Fehler beim registrieren!
                  </Alert.Heading>
                  <p>{error}</p>
                </Alert>
              )}
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <Form.Group className="mb-2 " as={Col} md={6} controlId="validationfirmenname">
                    <Form.Label>Firmenname</Form.Label>
                    <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend" >                  
                      <i className="fa fa-building"></i>
                      </InputGroup.Text>
                     
                      <Form.Control
                      
                        type="text"
                        required
                        placeholder="xxx Transporte"
                        autoComplete="organization"
                        value={registerData.firmenname}
                        onChange={(event) =>
                          setRegisterData({
                            ...registerData,
                            firmenname: event.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte einen Firmenamen eingeben.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>
                        Sieht gut aus!
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-2 " as={Col} md="6" controlId="validationtelefon">
                    <Form.Label>Handynummer</Form.Label>
                    <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">                  
                      <i className="fa fa-phone"></i>
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="+49xxxxxxxxxx"
                        autoComplete="tel"
                        required
                        value={registerData.telefon}
                        onChange={(event) => {
                          setRegisterData({
                            ...registerData,
                            telefon: event.target.value,
                          });
                          setRegisterDataUser({
                            ...registerDataUser,
                            telefon: event.target.value,
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {" "}
                        Bitte Handynummer eingeben!{" "}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>
                        Sieht gut aus!
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group
                  className="mb-2 "
                    as={Col}
                    md="6"
                    controlId="validationPostleitzahl"
                  >
                    <Form.Label>Postleitzahl</Form.Label>
                    <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend" >
                    <i className="fa fa-map-pin" aria-hidden="true" ></i>
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="20079"
                        autoComplete="postal-code"
                        required
                        minLength={5}
                        maxLength={5}
                        pattern="[0-9]{5}"
                        value={registerData.plz}
                        onChange={(event) => {
                          setRegisterData({
                            ...registerData,
                            plz: event.target.value,
                          });
                          setRegisterDataUser({
                            ...registerDataUser,
                            plz: event.target.value,
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte eine Postleitzahl von 5 Zeichen eingeben.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>
                        Sieht gut aus!
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-2 " as={Col} md="6" controlId="validationOrt">
                    <Form.Label>Firmensitz</Form.Label>
                    <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">                  
                      <i className="fa fa-globe" aria-hidden="true" ></i>
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Ort"
                        autoComplete="address-level2"
                        required
                        value={registerData.ort}
                        onChange={(event) => {
                          setRegisterData({
                            ...registerData,
                            ort: event.target.value,
                          });
                          setRegisterDataUser({
                            ...registerDataUser,
                            ort: event.target.value,
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {" "}
                        Bitte einen Ort eingeben!{" "}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>
                        Sieht gut aus!
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group className="mb-2 " as={Col} md="6" controlId="validationstrasse">
                    <Form.Label>Straße und Hausnummer</Form.Label>
                    <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">                  
                      <i className="fa fa-road" aria-hidden="true" ></i>
                      </InputGroup.Text>
                      <Form.Control
                        type="Multiline"
                        required
                        autoComplete="street-address"
                        placeholder="Strasse - Nr."
                        value={registerData.strasse}
                        onChange={(event) => {
                          setRegisterData({
                            ...registerData,
                            strasse: event.target.value,
                          });
                          setRegisterDataUser({
                            ...registerDataUser,
                            strasse: event.target.value,
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte eine Straße eingeben.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>
                        Sieht gut aus!
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                  className="mb-2 "
                    as={Col}
                    md="6"
                    controlId="validationansprechpartner"
                  >
                    <Form.Label>Ansprechpartner</Form.Label>
                    <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">                  
                      <i className="fa fa-user" aria-hidden="true" ></i>
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        required
                        autoComplete="name"
                        placeholder="Max Mustermann"
                        value={registerData.ansprechpartner}
                        onChange={(event) => {
                          setRegisterData({
                            ...registerData,
                            ansprechpartner: event.target.value,
                          });
                          setRegisterDataUser({
                            ...registerDataUser,
                            name: event.target.value,
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte einen Ansprechpartner eingeben.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>
                        Sieht gut aus!
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-2 " as={Col} md="6" controlId="validationemail">
                    <Form.Label>Email Adresse</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        @
                      </InputGroup.Text>
                      <Form.Control
                        type="email"
                        placeholder="Max@Mustermann.de"
                        autoComplete="username"
                        aria-describedby="inputGroupPrepend"
                        required
                        value={registerData.email}
                        onChange={(event) => {
                          setRegisterData({
                            ...registerData,
                            email: event.target.value,
                          });
                          setRegisterDataUser({
                            ...registerDataUser,
                            email: event.target.value,
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {" "}
                        Bitte eine Email Adresse eingeben!{" "}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>
                        Sieht gut aus!
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                  className="mb-2 "
                    as={Col}
                    md="6"
                    controlId="validationCustomPasswort"
                  >
                    <Form.Label>Passwort</Form.Label>
                    <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                  <i className="fa fa-lock"></i>
                 
                      </InputGroup.Text>
                   
                      <Form.Control
                        type="password"
                        placeholder="********"
                        required
                        minLength={6}
                        autoComplete="new-password"
                        onChange={(event) => setPasswort(event.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte ein Passwort von min 6 Zeichen eingeben.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>
                        Sieht gut aus!
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>

                <Button
                  type="submit"
                  className="w-100 mt-3"
                  variant="outline-success"
                  disabled={loding}
                >
                  Jetzt registrieren
                </Button>
              </Form>
            </Card.Body>
          </Card>
          <div className="text-center w-100 mt-2">
            <Link to="/login">Login?</Link>
          </div>
        </Col>
      </Container>
    </>
  );
}
export default Register;
