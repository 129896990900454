import React, { useEffect ,useState} from "react";
import { useAuth } from "../src/context/authContext";
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import Popup from "./components/Popup";


import "./App.css";
import {NoMatch} from "./components/pages/notFound/notfound";
import {MyNavbar} from "./components/navbar";
import Login from "./components/pages/login";
import Register from "./components/pages/register";
import Dashbord from "./components/pages/Dashbord";
import Services from "./components/pages/Services";
import ContactUs from "./components/pages/ContactUs";
import Urlaube from "./components/pages/Urlaube/Urlaube";
import {Datenschutz, Impressum} from "./components/pages/rechliches/rechtliches"
import {Mitarbeiter} from "./components/pages/mitarbeiter/Mitarbeiter";
import Rollen from "./components/pages/Rollen";
import {Stopps} from "./components/pages/stoppsauswertung/stopps";
import {Spesen} from "./components/pages/spesen/spesen";
import {Live} from "./components/pages/live/live";
import {Anwesenheit} from "./components/pages/anwesenheit/anwesenheit";
import LiveMap from "./components/pages/live/gMaps";
import {Fahrzeuge} from "./components/pages/Fahrzeuge/Fahrzeuge";
import {Notice} from "./components/pages/Notice/Notice";
import {Touren} from "./components/pages/Touren/Touren";
import {Verguetung} from "./components/pages/Verguetung/Verguetung";
import Kalender from "./components/pages/Kalender/Kalender";
import ForgotPassword from "./components/pages/forgotPassword";
import Download from "./components/pages/download";
import Abgemeldet from "./components/pages/abgemeldet";





const App = () => {
  
const { currentUser, isAdmin } = useAuth();



return (
  <React.Fragment>  
 
  {typeof isAdmin !=='undefined'  ? ( 

     <Router>
      <div className="App">
      <Popup />
    
   


        {currentUser ? <MyNavbar /> :  <Outlet/> }
       
        <div className="content" >
        <Routes> 
          {isAdmin ?           
            <Route path="/" element={<Live />} />
            :
            <Route path="/" element={<Dashbord />} /> 
          }         

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/dashboard" element={<Dashbord />} />
          <Route path="/services" element={<Services />} />
          <Route path="/fahrzeuge" element={<Fahrzeuge />} />
          <Route path="/stoppsauswertung" element={<Stopps />} />
          <Route path="/spesenanzeigen" element={<Spesen />} />
          <Route path="/live" element={<Live />} />
          <Route path="/liveMap" element={<LiveMap />} />
          <Route path="/anwesenheit" element={<Anwesenheit />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/urlaube" element={<Urlaube />} />
          <Route
            path="/mitarbeiter"             
            element={<Mitarbeiter />}
          />
          <Route path="/notice" element={<Notice />} />
          <Route path="/rollen" element={<Rollen />} />
          <Route path="/touren" element={<Touren />} />
          <Route path="/verguetung" element={<Verguetung />} />
          <Route path="/kalender" element={<Kalender />} />
          <Route path="/download" element={<Download />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="/impressum" element={<Impressum />} />   
          <Route path="/abgemeldet" element={<Abgemeldet />} />        
          <Route path="*"  element={<NoMatch />} />
          {/*<Route component={NoMatch} />
          <Route index element={NoMatch} />
           
          */}
        </Routes>
       
        </div>
        </div>
     </Router>
    
    ): (<></>)}
    </React.Fragment>
);

  
};




export default App;
