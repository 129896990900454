import React, { useState, useEffect } from "react";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import { Cube } from "react-preloaders2";
import isEmpty from 'lodash.isempty';
import { 
    Card,
    Tab,
    Row,
    Col,
    Button,
    ListGroup,
    Badge,
    Stack,
    Container,
  } from "react-bootstrap";
  import { ref, getDatabase, onValue } from "firebase/database";
  import AnwesenheitUser from "../anwesenheit/anwesendUser";
  import useWindowDimensions from '../../useMediaQuery.js';




export const Anwesenheit = () => {
    const { currentUser, isAdmin} = useAuth();
    const [FID, setFID] = useState("");
    const navigate = useNavigate();
    const [users , SetUsers] = useState([]);
    const [user , SetUser] = useState([]);
    const [loaduser, SetLoadUser] = useState(true);
    const [fahrzeuge , SetFahrzeuge] = useState([]);
    const [loadfahrzeuge, SetLoadFahrzeuge] = useState(true);
    const [touren , SetTouren] = useState([]);
    const [loadtouren, SetLoadTouren] = useState(true);
    const [isloading, SetIsLoading] = useState(true);
    const { height, width } = useWindowDimensions();
    const isMobile = width < 768;
    const [ShowAnweseheitUser,setShowAnweseheitUser] = useState(false)

    const db = getDatabase();
    const dbRef = ref(getDatabase());

    useEffect(() => {
        if (currentUser) {
          setFID(currentUser.photoURL);
        }
      }, [currentUser, navigate]);
    
      useEffect(() => {      
        if (!isAdmin) {
            navigate("/dashboard", { replace: true });
        }         
      }, [ navigate, isAdmin]);

    useEffect(() => {                    
            const dbRefTouren = ref(db, `touren/` + FID);
          return  onValue(dbRefTouren, (snap) => {
              if (snap.exists()) {
                  SetTouren(snap.val());               
                  SetLoadTouren(false);
                  return
                } else {
                  SetTouren([]);  
                  console.log("No data available");
                  return
                }
              })   
     
      },[FID, db,loadtouren])

     
    useEffect(() => {      
      const dbRef = ref(db, `users/` + FID);
      return onValue(dbRef, (snap) => {
            if (snap.exists()) {
                SetUsers(snap.val());              
                SetLoadUser(false);
                return;
              } else {
                SetUsers([])
                console.log("No data available");
                return;
              }
            })
    },[FID, db,loaduser])

    useEffect(() => {             
      const dbRefFZ = ref(db, `fahrzeuge/` + FID);
      return onValue(dbRefFZ, (snap) => {
          if (snap.exists()) {
              SetFahrzeuge(snap.val());               
              SetLoadFahrzeuge(false);
              return
            } else {
              SetFahrzeuge([]);  
              console.log("No data available");
              return
            }
          })
    },[FID, db,loadfahrzeuge])








      useEffect(() => {
        if(!loaduser && !loadfahrzeuge){
          SetIsLoading(false)        
        }
       
      }, [fahrzeuge, loadfahrzeuge, loaduser]);
     
    const handleClickUser = (v) => {   
        setShowAnweseheitUser(true) ;
        SetUser(v);
    }
 
    const childToParent = (state) => {
      console.log("childToParent=>",state)
     // ShowAnweseheitUser(state)
    
  
    }

    return (
       <>
       {!isloading ? (
        <Container fluid>       
            <Row>  
            {!isMobile || !ShowAnweseheitUser ? (
              <Col xs={12} sm={12} lg={3} md={4}>
                <Card className={width < 768 ? 'bg-light align-items-stretch myCardsx mt-3' : 'align-items-stretch myCard mt-3'}>
                    <Card.Header className=" d-flex justify-content-between align-middle">
                    <div>  </div>
                    <div> Mitarbeiter </div>
                    <div></div>
                    </Card.Header>
                    <Card.Body className="d-flex flex-column">
                    <Tab.Container id="TESTID">
                      <Row>
                        <Col sm={12}>
                          <ListGroup>
                              {Object.entries(users).map(([k, v]) => {
                                  return(
                                      <ListGroup.Item
                                      key={k}
                                      className="my-1 d-inline-block d-flex justify-content-between align-items-start"
                                      action onClick={() => {
                                              handleClickUser(v)
                                          }
                                      }
                                      >
                                      {`${v.name}`}
                                      </ListGroup.Item>
                                  )
                                
                              })}
                          </ListGroup>
                        </Col>
                      </Row>
                    </Tab.Container>
                    </Card.Body>
                </Card>
              </Col>
            ):(<></>)}
                {!isEmpty(user) && ShowAnweseheitUser ? (
                <React.Fragment>               
                    <Col xs={12} sm={12} lg={9} md={8}>  
                   
                        <AnwesenheitUser  User={user} Users={users} fahrzeuge={fahrzeuge} touren={touren} childToParent={childToParent} setShowAnweseheitUser={setShowAnweseheitUser}>

                        </AnwesenheitUser>    
                      
                    </Col>      
                </React.Fragment>   
                ):(<></>)}
            </Row>
        
          
        </Container>     
      ):(
        <Cube customLoading={isloading} time={0} background="#40bfb2" />
      )}
    </> 
      
    )

}