import React , { useEffect,useState}from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/authContext";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import cellEditFactory from "react-bootstrap-table2-editor";
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory, { numberFilter  } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

import * as moment from "moment-feiertage";
import Select from 'react-select'
import { useForm, Controller } from "react-hook-form";
import { 
  Row,
  Col,
  Button, 
  Form,
  InputGroup,
  Modal, 
  Container,  
} from "react-bootstrap";
import {
  push,
  child,
  remove,
  update,
    ref,  
    getDatabase,
    onValue,   
  } from "firebase/database";
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faTrash,faRoad} from '@fortawesome/free-solid-svg-icons'
  import useWindowDimensions from "../../useMediaQuery";
  import toast from "react-hot-toast";
  import { confirmAlert } from 'react-confirm-alert'; // Import
  import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
  import './styles.css'; 
 
  
const defaultSorted = [
    {
      dataField: "year",
      order: "desc"
    }
];
const options = [];

export default function Urlaube() {
  const { currentMitarbeiter, isAdmin, currentUser } = useAuth();
  const db = getDatabase(); 
  const [currentHollday, setCurrentHollday] = useState(null);  
  const { width } = useWindowDimensions();
  const isMobile = width < 768;  
  const [urlaubsdaten, setUrlaubsDaten] = useState([]);
  const [isLoading, setisLoading] = useState(true);  
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  
  let nameFilter;
  useEffect(() => {
    if (!isAdmin) {
      navigate(-1)
      
    }
  }, [isAdmin, navigate]);
  const deletesubmit = (row) => {  

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Bist du dir Sicher?</h1>
            <p>Das du den Urlaub von {row.name} für das Jahr {row.year} löschen möchtest.</p>
            <div className="d-flex justify-content-evenly">
            <Button variant="outline-primary"  onClick={onClose}>Abbrechen</Button>
            <Button
              variant="outline-danger"
              onClick={() => {
                const removes = ref(db,'/urlaube/' + currentUser.photoURL + "/" + row.id) 
                remove(removes);
                onClose();
              }}
            >
              Ja, lösch es!
            </Button>
            </div>
          </div>
        );
      }
    });



  };
  

  const linkDelete = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button variant="outline-danger"
        onClick={() => { 
         
         deletesubmit(row)       
        }}
      >
        <><div className="text-center"><FontAwesomeIcon icon={faTrash}/> Löschen</div></> 
      </Button>
    );
  };

 
  const textHeader = (column, colIndex, components) => {
   
    if(isMobile){
      switch(colIndex) {
        case 0:
          return 'Jahr';
        case 1:
          return 'Name';
        case 2:
          return 'Rest';
        case 3:
          return 'Anspruch';
        case 4:
          return 'Sonder- urlaub';
        default:
          return 'Löschen';
      }     
    }else{
      switch(colIndex) {
        case 0:
          return 'Jahr';
        case 1:
          return 'Mitarbeiter';
        case 2:
          return 'Rest von letzten Jahr';
        case 3:
          return 'Urlaubs Anspruch';
        case 4:
          return 'Sonderurlaub';
        default:
          return 'Löschen';
      }
    }
    
  
  
  };

  const defautValue = {
    anspruch: 24,
    rest: 0,
    sonderurlaub: 0,
    uid: "",
    year: new Date().getFullYear()
  }

  const columns = [
    {
        dataField: "id",
        text: "ID",
        hidden: true        
    },
    {
        dataField: "year",
        text: '', 
       /* filter: numberFilter({
          getFilter: filter => {
            nameFilter = filter;
          }
        }),*/
        headerFormatter: textHeader , 
        sort: true,
        headerAlign: 'center',
        align: 'center',
        editable: false,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => { 
            TableEvents(columnIndex,row)
          },
        }
       
    },
    {
        dataField: "uid",
        text: "uid",
        sort: true,
        editable: false,
        hidden: true
    } ,
    {
        dataField: "name",
        text: '',
        headerFormatter: textHeader , 
        align: 'center',
        headerAlign: 'center',
        sort: true,
        editable: false,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => { 
            TableEvents(columnIndex,row)
          },
        }
    },
    {
        dataField: "rest",
        text: '',
        headerFormatter: textHeader , 
        align: 'center',
        headerAlign: 'center',
        editable: false,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => { 
            TableEvents(columnIndex,row)
          },
        }
    },
    {
        dataField: "anspruch",
        text: '',
        headerFormatter: textHeader , 
        align: 'center',
        headerAlign: 'center',
        editable: false,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => { 
            TableEvents(columnIndex,row)
          },
        }
    },
    {
        dataField: "sonderurlaub",
        text: '',
        headerFormatter: textHeader ,     
        align: 'center', 
        headerAlign: 'center',
        editable: false,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => { 
            TableEvents(columnIndex,row)
          },
        }
       
    },{
      dataField: "delete",      
      text: 'Löschen',
      headerFormatter: textHeader ,  
      align: 'center',
      headerAlign: 'center',
      formatter: linkDelete,
      editable: false     
    },
];


  function TableEvents(columnIndex,row) {
      console.log("rowEvents=>",row)    
      setCurrentHollday(row)
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: "bold",
      color: state.data.color,
      
     // backgroundColor: state.data.color,
    //  fontSize: state.selectProps.myFontSize
    }),
    singleValue: (provided, state) => ({
      ...provided,
     // borderLeft: '10px dotted pink',
      color: state.data.color,
     
    //  fontSize: state.selectProps.myFontSize
    })
  }

 
 

  const saveCurrentHollday = () => {  
    
    console.log(currentHollday)
    if(!currentHollday.uid.value || isNaN(currentHollday.year) || isNaN(currentHollday.anspruch) || isNaN(currentHollday.rest) || isNaN(currentHollday.sonderurlaub)){
      toast.error('Es sind nicht alle Felder korrekt ausgefüllt');
      return
    }
      
   
   const updates = {};   
   updates['/urlaube/' + currentUser.photoURL + "/" +currentHollday.year + currentHollday.uid.value] = currentHollday;
   update(ref(db), updates);
   setCurrentHollday(null) 


  }

 

  function indication() {
    return "Noch keine Daten vorhanden";
  }

  useEffect(() => {
    
    if(urlaubsdaten){       
        setisLoading(false)
    }

  }, [urlaubsdaten])

  

  useEffect(() => {
     
      if( currentMitarbeiter.length !==0 ){         
      options.length = 0;
      Object.entries(currentMitarbeiter).forEach(element =>{
        options.push({
          value: element[0],
          label: element[1]["name"],
          color: element[1]["color"]
        })       
      })
       
    

     
        const urlaubref = ref(db, 'urlaube/'+ currentUser.photoURL)
        onValue(urlaubref, (snapshot) => {   
            if (snapshot.exists()) {  
                const response=[];
                snapshot.forEach(function(childSnapshot)  {
                let childdata = childSnapshot.val();
                let childkey = childSnapshot.key;     
                response.push({
                    id: childkey,
                    year: childdata["year"],
                    uid: childdata["uid"],
                    name: childdata["uid"]["label"],
                    rest: childdata["rest"],
                    anspruch: childdata["anspruch"],
                    sonderurlaub: childdata["sonderurlaub"],               
                    });
                }) 
                    setUrlaubsDaten(response)
            }else{
               
                setUrlaubsDaten([])
            };          
          });

        }
  }, [currentMitarbeiter, currentUser, db]);
  


  return (
    <>
     

      <Container fluid>
      <div className="mt-3 d-grid gap-2 d-md-flex justify-content-md-end">
        <Button          
          variant="outline-primary"
          onClick={() => setCurrentHollday(defautValue)}
        >
          Neuen Urlaub hinzufügen
        </Button>
      </div>
       
        <div className="mt-3">
        <BootstrapTable
          bootstrap4={ true }
          data={urlaubsdaten} 
          columns={columns} 
          keyField='id' 
        >
       
        </BootstrapTable>
        </div>
      </Container>
      <Modal show={currentHollday} onHide={() => setCurrentHollday(null)}>
            {currentHollday && (
              <div>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {currentHollday.id !== undefined
                      ? `${currentHollday.name} Urlaub bearbeiten.`
                      : "Neuer Urlaub"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                  <Row className="mb-3 ">
                    <Form.Group as={Col} className="col-md-12" controlId="validationCustom01">
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="col-5"                         
                        >
                          Mitarbeiter:
                        </InputGroup.Text>                       
                        <Select        
                        aria-describedby="inputGroupPrepend"                       
                        className="col-7"                                   
                        styles={customStyles}
                        options={options}
                        value={currentHollday.uid || ''}
                        onChange={(e) => {
                         
                          setCurrentHollday(
                            { ...currentHollday, uid: e }
                          )
                        }
                        }
                      />
                      
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 ">
                    <Form.Group
                      as={Col}
                      className="col-md-12"
                      id="validationAnlass"
                    >
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="col-5"
                          id="inputGroupPrepend"
                        >
                          Jahr:
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          placeholder="Jahr"
                          min="2010" max="2100"
                          aria-describedby="inputGroupPrepend"
                          required
                          value={currentHollday.year}
                          onChange={(e) =>                            
                            setCurrentHollday({
                              ...currentHollday,
                              year: e.target.valueAsNumber,
                            })
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                   
                  </Row>
                  <Row className="mb-3 ">
                    <Form.Group
                      as={Col}
                      className="col-md-12"
                      id="validationmindate"
                    >
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="col-5"
                          id="inputGroupPrepend"
                        >
                          Anspruch:
                        </InputGroup.Text>
                        <Form.Control                        
                          id="min-date"
                          type="number"
                          aria-describedby="inputGroupPrepend"
                          required
                          value={currentHollday.anspruch}
                          onChange={(e) =>
                           setCurrentHollday({
                              ...currentHollday,
                              anspruch: e.target.valueAsNumber,
                            })
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 ">
                    <Form.Group
                      as={Col}
                      className="col-md-12"
                      id="validationmindate"
                    >
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="col-5"
                          id="inputGroupPrepend"
                        >
                          Restübertrag:
                        </InputGroup.Text>
                        <Form.Control                        
                          type="number"
                          min="0" max="100"
                          aria-describedby="inputGroupPrepend"
                          required
                          value={currentHollday.rest}
                          onChange={(e) =>
                            setCurrentHollday({
                              ...currentHollday,
                              rest: e.target.valueAsNumber,
                            })
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 ">
                    <Form.Group
                      as={Col}
                      className="col-md-12"
                      id="validationCustomName"
                    >
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="col-5"
                          id="inputGroupPrepend"
                        >
                          Sonderurlaub:
                        </InputGroup.Text>
                        <Form.Control                         
                          type="number"
                          aria-describedby="inputGroupPrepend"
                          required
                          min="0" max="100"
                          value={currentHollday.sonderurlaub}
                          onChange={(e) =>
                            setCurrentHollday({
                              ...currentHollday,
                              sonderurlaub: e.target.valueAsNumber,
                            })
                            }
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  </Form>
               
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="outline-danger"
                    onClick={() => setCurrentHollday(null)}
                  >
                    Abbrechen
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => saveCurrentHollday()}
                  >
                    Urlaub Speichern
                  </Button>
                </Modal.Footer>
                
              </div>
            )}
          </Modal>
    </>
  );
}
