import React, { useState, useEffect } from "react";
import { set as set1, format } from "date-fns";
import {
  InputGroup,
  Card,
  Form,
  Col,
  Row,
  Button,
  Tabs,
  Tab,
  OverlayTrigger,
  Popover,
  Stack,
} from "react-bootstrap";
import useWindowDimensions from "../../useMediaQuery";
import { useAuth } from "../../../context/authContext";
import DatePicker, { registerLocale } from "react-datepicker";
import Select from "react-select";
import Switch from "react-switch";
import de from "date-fns/locale/de";
registerLocale("de", de);

export const FZForm = ({ childToParent, setShowNewFZ, formdata, IsnewFZ }) => {
  const [validated, setValidated] = useState(true);
  const { width } = useWindowDimensions();
  const { currentUser } = useAuth();
  const FID = currentUser.photoURL;
  const [FZid, setFZid] = useState("");
  const [fzart, setfzart] = useState(true);
  const [leihFZ, setleihFZ] = useState(false);
  const [isAktiv, setisAktiv] = useState(true);

  const styles = {
    myCoolButton: {
      paddingRight: "1vw",
    },
  };
  const [FZdata, setFormData] = useState({
    aktiveDate: new Date().getTime(),
    createDate: new Date().getTime(),
    fid: FID,
    isAktiv: true,
    kennzeichen: "",
    islkw: true,
    zuladung: 0,
    antrieb: [{ value: 1, label: "Diesel" }],
    euronorm: [{ value: 3, label: "Euro 6" }],
    isleihfahrzeug: false,
    beschriftung: "",
    kmStand: 0,
    imei: "",
  });

  const [mhd, setmhd] = useState({
    erstzulassung: 0,
    tuev: 0,
    sp: 0,
    fahrtenschreiber: 0,
    uvv: 0,
    feuerloescher: 0,
    augenspuehlflsche: 0,
    notfallfluchtmaske: 0
  });

  const optionsantrieb = [
    { value: 1, label: "Diesel" },
    { value: 2, label: "Benzin" },
    { value: 3, label: "Eletro" },
  ];

  const optionseuronorm = [
    { value: 1, label: "Euro 4" },
    { value: 2, label: "Euro 5" },
    { value: 3, label: "Euro 6" },
    { value: 4, label: "Euro d-TEMP" },
  ];

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3" className="text-center">
        Verknüfung mit Endgerät
      </Popover.Header>
      <Popover.Body>
        Hier bitte die Imei - Nummer des <strong>(Android)</strong> Endgerätes
        eingeben das mit diesen Fahrzeug verbunden sein soll.
        <br />
        <br />
        Dies können Sie auch über das Endgerät Automatisch selber machen lassen.
      </Popover.Body>
    </Popover>
  );

  const handleChange = (nextChecked) => {
    setfzart(nextChecked);
    setFormData({ ...FZdata, islkw: nextChecked });
  };
  const handleChangeisaktiv = (AktivChecked) => {
    setisAktiv(AktivChecked);
    setFormData({ ...FZdata, isAktiv: AktivChecked });
  };

  const handleChangeLeihfahrzeug = (LHChecked) => {
    setleihFZ(LHChecked);
    setFormData({ ...FZdata, isleihfahrzeug: LHChecked });
  };

  useEffect(() => {
    if (!IsnewFZ) {
      setFZid(formdata.key);
      setFormData(formdata.fz);
    }
  }, [IsnewFZ, formdata]);

  useEffect(() => {}, [fzart]);

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => childToParent(e, FZdata, mhd, FZid)}
        className="mt-3"
      >
        <Card
          className={
            width < 768
              ? "bg-light align-items-stretch myCardsx"
              : "align-items-stretch myCard"
          }
        >
          <Card.Header className="text-center">
            Allgemeine angaben über das neue Fahrzeug
          </Card.Header>

          <Card.Body className="d-flex flex-column">
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Allgemeine Angaben">
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Stack direction="horizontal" gap={3}>
                      <Form.Label>Art des Fahrzeuges</Form.Label>
                      <label>
                        <Switch
                          className="ms-auto"
                          onChange={handleChange}
                          checked={FZdata.islkw}
                          offColor="#f0a000"
                          onColor="#00a000"
                          uncheckedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 12,
                                color: "black",
                                paddingRight: 2,
                              }}
                            >
                              PKW
                            </div>
                          }
                          checkedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 12,
                                color: "black",
                                paddingRight: 2,
                              }}
                            >
                              LKW
                            </div>
                          }
                        />
                      </label>
                    </Stack>
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Stack direction="horizontal" gap={3}>
                      <Form.Label>Ist es ein Leihfahrzeug</Form.Label>
                      <label>
                        <Switch
                          onChange={handleChangeLeihfahrzeug}
                          checked={FZdata.isleihfahrzeug}
                          onColor="#f0a000"
                          offColor="#00a000"
                          uncheckedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 12,
                                color: "black",
                                paddingRight: 2,
                              }}
                            >
                              Nein
                            </div>
                          }
                          checkedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 12,
                                color: "black",
                                paddingRight: 2,
                              }}
                            >
                              Ja
                            </div>
                          }
                        />
                      </label>
                    </Stack>
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Stack direction="horizontal" gap={3}>
                      <Form.Label>Ist Aktiv</Form.Label>
                      <label>
                        <Switch
                          onChange={handleChangeisaktiv}
                          checked={FZdata.isAktiv}
                          offColor="#f0a000"
                          onColor="#00a000"
                          uncheckedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 12,
                                color: "black",
                                paddingRight: 2,
                              }}
                            >
                              Nein
                            </div>
                          }
                          checkedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 12,
                                color: "black",
                                paddingRight: 2,
                              }}
                            >
                              Ja
                            </div>
                          }
                        />
                      </label>
                    </Stack>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md="6"
                    style={styles.myCoolButton}
                    controlId="validationKennzeichen"
                  >
                    <Form.Label>Kennzeichen</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Kennzeichen"
                      value={FZdata.kennzeichen || ""}
                      onChange={(event) =>
                        setFormData({
                          ...FZdata,
                          kennzeichen: event.target.value,
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Bitte Kennzeichen eingeben!{" "}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>
                      Sieht gut aus!
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="validationAntrieb">
                    <Form.Label>Antreibsart</Form.Label>
                    <Select
                      className="mb-2"
                      options={optionsantrieb}
                      value={FZdata.antrieb || ""}
                      onChange={(e) => {
                        setFormData({ ...FZdata, antrieb: e });
                      }}
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-3 ">
                  {FZdata.islkw ? (
                    <Form.Group as={Col} md="6" controlId="validationZulassung">
                      <Form.Label>Zuladung in KG</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="KG"
                        required
                        pattern="[0-9]{3,5}"
                        value={FZdata.zuladung || ""}
                        onChange={(event) =>
                          setFormData({
                            ...FZdata,
                            zuladung: parseInt(event.target.value),
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {" "}
                        Bitte Nutzlast eingeben!{" "}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>
                        Sieht gut aus!
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    <></>
                  )}
                  <Form.Group
                    as={Col}
                    md="6"
                    style={styles.myCoolButton}
                    controlId="validationArt"
                  >
                    <Form.Label>Art</Form.Label>
                    <Select
                      className="mb-2"
                      options={optionseuronorm}
                      value={FZdata.euronorm || ""}
                      onChange={(e) => {
                        setFormData({ ...FZdata, euronorm: e });
                      }}
                    />
                  </Form.Group>
                  {FZdata.islkw && !FZdata.isleihfahrzeug ? (
                    <Form.Group
                      as={Col}
                      md="6"
                      style={styles.myCoolButton}
                      controlId="validationBeschriftung"
                    >
                      <Form.Label>Beschriftung</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Schenker"
                        required
                        value={FZdata.beschriftung || ""}
                        onChange={(event) =>
                          setFormData({
                            ...FZdata,
                            beschriftung: event.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {" "}
                        Bitte eine Beschriftung eingebn.{" "}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>
                        Sieht gut aus!
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    <></>
                  )}
                  <Form.Group as={Col} md="6" controlId="validationCustom05">
                    <Form.Label>KM - Stand</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="KM Stand"
                      required
                      pattern="[0-9]{1,7}"
                      value={FZdata.kmStand || ""}
                      onChange={(event) =>
                        setFormData({ ...FZdata, kmStand: parseInt(event.target.value )})
                      }
                    />

                    <Form.Control.Feedback type="invalid">
                      Bitte einen Kilometerstand eingeben.
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>
                      Sieht gut aus!
                    </Form.Control.Feedback>
                  </Form.Group>
                  <OverlayTrigger placement="bottom" overlay={popover}>
                    <Form.Group as={Col} md="6" controlId="validationImei">
                      <Form.Label>Imei Nummer</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Imei Nummer"
                        pattern="[0-9A-Za-z]{5,20}"
                        maxLength={20}
                        minLength={5}
                        value={FZdata.imei || ""}
                        onChange={(event) =>
                          setFormData({ ...FZdata, imei: event.target.value })
                        }
                      />

                      <Form.Control.Feedback type="invalid">
                        Bitte einen Code eingeben der zwischen 5 und 20 stellen hat.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>
                        Sieht gut aus!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </OverlayTrigger>
                </Row>
              </Tab>
              <Tab eventKey="date" title="Verfalldaten">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <InputGroup.Text className="h-100">
                      Erstzulassung
                    </InputGroup.Text>
                  </div>
                  <div className="col-md-6">
                    <DatePicker
                      className="w-100"
                      placeholderText="Erstzulassung"
                      locale="de"
                      selected={mhd.erstzulassung || ""}
                      onSelect={(e) => {}}
                      onChange={(e) => {
                        setmhd({
                          ...mhd,
                          erstzulassung: e.getTime(),
                        });
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      scrollableYearDropdown
                      dateFormat="dd.MM.yyyy"
                      dropdownMode="select"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <InputGroup.Text className="h-100">TÜV</InputGroup.Text>
                  </div>
                  <div className="col-md-6">
                    <DatePicker
                      className="w-100"
                      locale="de"
                      selected={mhd.tuev || 0}
                      placeholderText="TÜV"
                      onChange={(e) => {
                        setmhd({
                          ...mhd,
                          tuev: e.getTime(),
                        });
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      scrollableYearDropdown
                      dateFormat="dd.MM.yyyy"
                      dropdownMode="select"
                    />
                  </div>
                </div>
                {fzart ? (
                  <Form.Group as={Col} md="12">
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <InputGroup.Text className="h-100">
                          Nächste SP
                        </InputGroup.Text>
                      </div>
                      <div className="col-md-6">
                        <DatePicker
                          className="w-100"
                          locale="de"
                          selected={mhd.sp || 0}
                          placeholderText="Nächste SP"
                          onChange={(e) => {
                            setmhd({
                              ...mhd,
                              sp: e.getTime(),
                            });
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dateFormatCalendar="MMMM"
                          scrollableYearDropdown
                          dateFormat="dd.MM.yyyy"
                          dropdownMode="select"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <InputGroup.Text className="h-100">
                          Fahrtenschreiber Prüfung
                        </InputGroup.Text>
                      </div>
                      <div className="col-md-6">
                        <DatePicker
                          className="w-100"
                          locale="de"
                          selected={mhd.fahrtenschreiber || 0}
                          placeholderText="Fahrtenschreiber Prüfung"
                          onChange={(e) => {
                            setmhd({
                              ...mhd,
                              fahrtenschreiber: e.getTime(),
                            });
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dateFormatCalendar="MMMM"
                          scrollableYearDropdown
                          dateFormat="dd.MM.yyyy"
                          dropdownMode="select"
                        />
                      </div>
                    </div>
                  </Form.Group>
                ) : (
                  <></>
                )}
                <div className="row mb-3">
                  <div className="col-md-6">
                    <InputGroup.Text className="h-100">UVV</InputGroup.Text>
                  </div>
                  <div className="col-md-6">
                    <DatePicker
                      className="w-100"
                      locale="de"
                      selected={mhd.uvv || 0}
                      placeholderText="UVV"
                      onChange={(e) => {
                        setmhd({
                          ...mhd,
                          uvv: e.getTime(),
                        });
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      scrollableYearDropdown
                      dateFormat="dd.MM.yyyy"
                      dropdownMode="select"
                    />
                  </div>
                </div>
                {fzart ? (
                  <Form.Group as={Col} md="12">
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <InputGroup.Text className="h-100">
                          Feuerlöscher Prüfung
                        </InputGroup.Text>
                      </div>
                      <div className="col-md-6">
                        <DatePicker
                          className="w-100"
                          locale="de"
                          selected={mhd.feuerloescher || 0}
                          placeholderText="Feuerlöscher Prüfung"
                          onChange={(e) => {
                            setmhd({
                              ...mhd,
                              feuerloescher: e.getTime(),
                            });
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dateFormatCalendar="MMMM"
                          scrollableYearDropdown
                          dateFormat="dd.MM.yyyy"
                          dropdownMode="select"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <InputGroup.Text className="h-100">
                          Augenspühlflasche erneuern
                        </InputGroup.Text>
                      </div>
                      <div className="col-md-6">
                        <DatePicker
                          className="w-100"
                          locale="de"
                          selected={mhd.augenspuehlflsche || 0}
                          placeholderText="Augenspühlflasche erneuern"
                          onChange={(e) => {
                            setmhd({
                              ...mhd,
                              augenspuehlflsche: e.getTime(),
                            });
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dateFormatCalendar="MMMM"
                          scrollableYearDropdown
                          dateFormat="dd.MM.yyyy"
                          dropdownMode="select"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <InputGroup.Text className="h-100">
                          Notfallfluchtmaske erneuern
                        </InputGroup.Text>
                      </div>
                      <div className="col-md-6">
                        <DatePicker
                          className="w-100"
                          locale="de"
                          selected={mhd.notfallfluchtmaske || 0}
                          placeholderText="Notfallfluchtmaske erneuern"
                          onChange={(e) => {
                            setmhd({
                              ...mhd,
                              notfallfluchtmaske: e.getTime(),
                            });
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dateFormatCalendar="MMMM"
                          scrollableYearDropdown
                          dateFormat="dd.MM.yyyy"
                          dropdownMode="select"
                        />
                      </div>
                    </div>
                  </Form.Group>
                ) : (
                  <></>
                )}
              </Tab>
            </Tabs>
          </Card.Body>
          <Card.Footer>
            <div className="row">
              <div className="col d-grid gap-2 mt-auto">
                <Button
                  variant="outline-danger "
                  onClick={() => {
                    setShowNewFZ(false);
                  }}
                >
                  Abbrechen
                </Button>
              </div>
              <div className="col d-grid gap-2 mt-auto">
                <Button
                  className="float-right"
                  variant="outline-success"
                  type="submit"
                >
                  {IsnewFZ ? "Speichern" : "Update"}
                </Button>
              </div>
            </div>
          </Card.Footer>
        </Card>
      </Form>
    </>
  );
};
