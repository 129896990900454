import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Container,
  Dropdown,
  Form,
  Modal,
} from "react-bootstrap";
import "../../../App.css";
import { useAuth } from "../../../context/authContext";
import BootstrapTable from "react-bootstrap-table-next";
import Moment from "moment-feiertage";
import {
  child,
  orderByKey,
  startAt,
  endAt,
  query,
  ref,
  getDatabase,
  get,
} from "firebase/database";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfYear,
  startOfMonth,
  endOfMonth,
  endOfYear,
  addMonths,
  addYears,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
  addWeeks,
} from "date-fns";
import { Scrollbars } from 'react-custom-scrollbars-2';
import isEmpty from 'lodash.isempty';
import { useNavigate } from "react-router-dom";
import BasicDocument from "./pdfdocument";
import { BlobProvider } from "@react-pdf/renderer";
import moment from "moment-feiertage";
import '../spesen/custom.css';

registerLocale("de", de);


const formatDate = (timestamp) => {
  return new Intl.DateTimeFormat("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(timestamp);
};
const formatTime = (timestamp) => {
  const timeOptions = {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  };
  return new Intl.DateTimeFormat("de-DE", {
    timeZone: "Europe/Berlin",
    ...timeOptions,
  }).format(timestamp); 
};
const milltoHHMMSS = (Mills) =>{
  var secends = moment.duration(Mills).seconds();
  var minutes = moment.duration(Mills).minutes();
  var hours = Math.trunc(moment.duration(Mills).asHours());
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (hours < 10) {
    hours = '0' + hours;
  }
  return hours + ':' + minutes

}
const SpesenFormat = (value) => {
  return (
    <div className={value === 0 ? "dot1" : ""}>
      {" "}
      {new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(value)}{" "}
    </div>
  );
};
const numberFormat = (value) => {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(value);
};
function priceFormatter(column, colIndex, { text }) {
  return <>{numberFormat(text)}</>;
}





export const Spesen = () => {
  const { currentUser, isAdmin } = useAuth();
  const [FID, setFID] = useState("");
  const [user, setUser] = useState([]);
  const [arbeitstage, setArbeitstage] = useState([]);
  const [loadingUser, setLoadingUser] = useState(true);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [laodingData, setlaodingData] = useState(true);
 // const [columns, setColumns] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [showpdf, setShowPdf] = useState(false);
  const [url, seturl] = useState(null);
  const db = getDatabase();
  const navigate = useNavigate();
  let lastId  = 0;

  // Create styles

  const noSpesenStyle = {
    borderleft: "5px solid red",
  };

  useEffect(() => {
    if (currentUser) {
      setFID(currentUser.photoURL);
    }
    if (!currentUser) navigate("/login", { replace: true });
  }, [currentUser, navigate]);

  useEffect(() => {
    if (arbeitstage) {
      setlaodingData(false);
    }
  }, [arbeitstage]);

  useEffect(() => {
    //User Laden
    if (FID) {
      get(child(ref(getDatabase()), `users/` + FID))
        .then((snapshot) => {
          if (snapshot.exists()) {
            var resual = [];
            const data = snapshot.val();
            const unsort = Object.keys(data).map((k) => ({
              id: k,
              ...data[k],
            }));
            if (isAdmin) {
              resual = unsort.filter((user) => user.isDelete === false);
            } else {
              resual = unsort.filter((user) => user.id === currentUser.uid);
            }

            setUser(resual);
            setLoadingUser(false);
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [FID]);

  useEffect(() => {
    handleChange(new Date());
  }, [user]);

 // useEffect(() => {
    const columns = [
        {
          dataField: "id",
          text: "USerID",
          headerAlign: "center",
          hidden: true,
        },
        {
          dataField: "name",
          text: "Mitarbeiter",
          headerAlign: "center",
          hidden: false,
          footer: "",
          footerAttrs: { colSpan: `5` },
          footerStyle: (column, colIndex) => {
            return {
              backgroundColor: "#81c784",
            };
          },
        },
        {
          dataField: "datum",
          text: "Datum",
          formatter: formatDate,
          headerAlign: "center",
          align: "center",

          footerStyle: (column, colIndex) => {
            return {
              backgroundColor: "#81c784",
            };
          },
        },
        {
          dataField: "beginn",
          text: "Arbeitsbeginn",
          formatter: formatTime,
          headerAlign: "center",
          align: "center",
        },
        {
          dataField: "pause",
          text: "Pause",
          headerAlign: "center",
          align: "center",
          formatter: milltoHHMMSS,
        },
        {
          dataField: "feierabend",
          text: "Feierabend",
          formatter: formatTime,
          headerAlign: "center",
          align: "center",
        },
        {
          dataField: "stunden",
          text: "Arbeitstunden",
          headerAlign: "center",
          formatter: ArbeitzeitFormatter,
          align: "center",
          footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          footerFormatter: GesamtArbeitzeitFormatter,
          footerAlign: "center",
          footerStyle: (column, colIndex) => {
            return {
              backgroundColor: "#81c784",
            };
          },
        },
        {
          dataField: "spesen",
          text: "Spesen",
          formatter: SpesenFormat,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              //  console.log(row);
              if (row.spesen !== 0) return;
              setCurrentEvent(row);
              // alert('Click on Product ID field');
            },
          },
          headerAlign: "center",
          align: "right",
          footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          footerFormatter: priceFormatter,
          footerAlign: (column, colIndex) => "right",
          footerStyle: (column, colIndex) => {
            return {
              backgroundColor: "#81c784",
            };
          },
        },
        {
          dataField: "tagesabschluss",
          text: "Tagesabschluss",
          headerAlign: "center",
          align: "center",
          hidden: true,
        },
      ];
  

  //}, [arbeitstage]);

  const handleChange = (date) => {
    setStartDate(date);
    

    getData(
      String(startOfMonth(date).setHours(0, 0, 0, 0)),
      String(endOfMonth(date).setHours(23, 59, 59, 0))
    );
  };

  

  const ModalEntered = () => {
    if (currentEvent) {
    }
  };

  const getData = (start, ende) => {
   
    const queryConstraints = [orderByKey(), startAt(start), endAt(ende)];
    get(query(ref(db, "dailybusiness/" + FID), ...queryConstraints)).then(
      (data) => {
        if (data.exists()) {
          setArbeitstage([]);
          if (isAdmin) {
            Object.entries(user).forEach((Userkey, valueUser) => {
             // console.log("valueUser=>",valueUser);
             // console.log("Userkey=>",Userkey);
              const SP = getSpesenDataforMitarbeiter(
                data,
                user[valueUser]["id"]
              );
              if (user[valueUser].isSpesen) {
               
                if (SP !== null && SP.length > 0) {
                  setArbeitstage((arbeitstage) => [...arbeitstage, SP]);
                }
              }
            });
          } else {
            const SP = getSpesenDataforMitarbeiter(data, currentUser.uid);
            setArbeitstage((arbeitstage) => [...arbeitstage, SP]);
          }
        }
      }
    );
  };

  function getSpesenDataforMitarbeiter(data, UserID) {
    let mitarbeiterSpesen = [];
    Object.entries(data.val()).forEach(([key, value]) => {
      const entries = Object.entries(value.anwesenheit).find(
        (item) => item[0] === UserID
      );
      const usernow = user.find((a) => a.id === UserID);

      if (entries) {
       if(entries[1].tagesabschluss){
        lastId++;

          let millsek = entries[1].end - entries[1].beginn;
          const StundenFormat = Moment(millsek - 60 * 60 * 1000).hour();
          let pause;
          let spesenbetrag = 0;
          let infoContent = "";
          if (StundenFormat > 8) {
            pause = 2700000;
          } else if (StundenFormat >= 6) {
            pause = 1800000;
          } else if (StundenFormat <= 5) {
            pause = 0;
          }
          if (entries[1].spesen) {
            spesenbetrag = 14;
          } else {
            if (entries[1].tagesabschluss) {
              infoContent = (
                <>
                  <h5>Zu früh Feierabend gemacht</h5>
                  <div>
                    Sie haben am {formatDate(entries[1].beginn)} von{" "}
                    {formatTime(entries[1].beginn)} bis{" "}
                    {formatTime(entries[1].end)} gearbeitet.
                  </div>
                  <div>
                    Dadurch haben Sie nicht die geforderten 08:00 Stunden
                    gearbeitet.
                  </div>
                  <div>
                    Ihre Arbeitzeit betrug: {ArbeitzeitFormatter(millsek - pause)}{" "}
                    Stunden. Die Pause wurde von der Arbeitszeit angezogen.
                  </div>
                  <div>
                    Die Pause von {ArbeitzeitFormatter(pause)} wurde von der
                    Arbeitszeit angezogen.
                  </div>
                </>
              );
            } else {
              infoContent = (
                <>
                  <h5>Keinen Tagesabschluss gemacht.</h5>
                  <div>
                    Sie haben am {formatDate(entries[1].beginn)} keinen
                    Tagesabschluss gemacht.
                  </div>
                  <div>
                    Dadurch haben wir nicht die möglichkeit festzustellen wann Sie
                    wirklich Feierabend gemacht haben.
                  </div>
                </>
              );
            }
          }
          millsek = millsek - pause;

          mitarbeiterSpesen.push({
            key: lastId,
            id: UserID,
            name: usernow.name,
            datum: entries[1].beginn,
            beginn: entries[1].beginn,
            pause: pause,
            feierabend: entries[1].end,
            stunden: millsek,
            spesen: spesenbetrag,
            tagesabschluss: entries[1].tagesabschluss,
            infoContent: infoContent,
          });
      }else{

      if(typeof entries[1]['places'] !== 'undefined'){
       const arr = entries[1]['places']['Feierabend Zone'];

       let lastEnter = Object.entries(arr).filter(
        ([times, value]) => value === 'Enter').pop();

      
        lastId++;
      
        let millsek = lastEnter[0] - entries[1].beginn;
        
      
       
        const StundenFormat = Moment(millsek - 60 * 60 * 1000).hour();
        let pause = 0;
        if (StundenFormat > 8) {
          pause = 2700000;
        } else if (StundenFormat >= 6) {
          pause = 1800000;
        } else if (StundenFormat <= 5) {
          pause = 0;
        }
        let spesenbetrag = 0;
        let infoContent = "";
        infoContent = (
          <>
            <h5>Keinen Tagesabschluss gemacht.</h5>
            <div>
              Sie haben am {formatDate(entries[1].beginn)} keinen
              Tagesabschluss gemacht.
            </div>
            <div>
              Dadurch haben wir nicht die möglichkeit, festzustellen wann Sie
              wirklich Feierabend gemacht haben.
            </div>
          </>
        );
        mitarbeiterSpesen.push({
          key: lastId,
          id: UserID,
          name: usernow.name,
          datum: entries[1].beginn,
          beginn: entries[1].beginn,
          pause: pause,
          feierabend: lastEnter[0],
          stunden: millsek,
          spesen: spesenbetrag,
          tagesabschluss: entries[1].tagesabschluss,
          infoContent: infoContent,
        });
      }

      }

      }
    });

    if (mitarbeiterSpesen.length !== 0) return mitarbeiterSpesen;
    return null;
  }

 

 


  function ArbeitzeitFormatter(zeitinMill) {
    return milltoHHMMSS(zeitinMill);  
  }

  function GesamtArbeitzeitFormatter(zeiticolumn, colIndex, { text }) {
    return milltoHHMMSS(text);  
  }

  
  const ConsoleLog = ({ children }) => {
  //  console.log(children);
    return false;
  };
 
 

  return (
    <>
      <Container>
      
        <div className="row ml-1 pb-2 mt-4 mb-2 border-bottom">
        <Row>
          <Col md="auto"><h4>Spesen für</h4></Col>
          <Col md="auto"> <DatePicker
                selected={startDate}
                onChange={(date) => handleChange(date)}
                selectsStart
                startDate={startDate}
                locale="de"
               
                dateFormat="MMMM yyyy"
                showMonthYearPicker
              /></Col>
          <Col md="auto">
          {!isEmpty(arbeitstage) ? (
           
           <><ConsoleLog>{arbeitstage}</ConsoleLog><BlobProvider
               document={<BasicDocument
                 key={arbeitstage.key}
                 data={arbeitstage}
                 userinfo={user}
                 />}
             >
               {({ url }) => (
                 <Button
                   variant="outline-success"                  
                   disabled={arbeitstage.length === 0}
                   href={url}
                   target="_blank"
                   rel="noopener noreferrer"
                 >
                   PDF erstellen
                 </Button>
               )}
             </BlobProvider></>
           ):(<></>)}
          </Col>

        </Row>
         

        </div>
        <div className="row mt-3 ml-1" id="buttonRow"></div>
        <div className="mt-3">
         

          {arbeitstage ? (
            <div style={{ padding: "0px" }}>
            <Scrollbars autoHeight autoHeightMax="calc(100vh)">

                {arbeitstage.map((item, index) => {
                  return (
                    <Row className={index > 0 ? "mt-3" : ""} key={index}>
                      <BootstrapTable
                        striped
                     //   hover
                     //   condensed
                       // bordered
                        bootstrap4={true}
                        keyField="key"
                        key={item.key}
                        data={item}
                        columns={columns}
                      
                      ></BootstrapTable>
                    </Row>
                  );
                })}
            </Scrollbars>
            </div>
          ) : (
            <></>
          )}
        </div>

        <Modal
          show={currentEvent}
          onHide={() => setCurrentEvent(null)}
          onEntering={() => ModalEntered()}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          {currentEvent && (
            <div>
              <Modal.Header closeButton>
                <Modal.Title>Warum habe ich keine Spesen bekommen?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="mb-3 ">{currentEvent.infoContent}</Row>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="outline-secondary"
                  disabled={false}
                  className="shadow"
                  onClick={() => {
                    setCurrentEvent(null);
                  }}
                >
                  OK Verstanden
                </Button>
              </Modal.Footer>
            </div>
          )}
        </Modal>
      </Container>
    </>
  );
};
