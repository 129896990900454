import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Col,
  Row,
  Button,
  Tabs,
  Tab,
  OverlayTrigger,
  Popover,
  Stack,
  InputGroup,
} from "react-bootstrap";
import {
  ref,
  getDatabase,
  push,
  child,
  update,
  onValue,
} from "firebase/database";
import useWindowDimensions from "../../useMediaQuery";
import { useAuth } from "../../../context/authContext";
import Select from "react-select";
import Switch from "react-switch";
import de from "date-fns/locale/de";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEuroSign, faPercent } from '@fortawesome/free-solid-svg-icons'

export const VerguetungForm = ({
  childToParent,
  setShowNewVerguetung,
  formdata,
  IsnewVerguetung,
}) => {

  const eurosign = <FontAwesomeIcon icon={faEuroSign} />;
  
  const percentsign = <FontAwesomeIcon icon={faPercent} />;
  const [validated, setValidated] = useState(true);
  const { width } = useWindowDimensions();
  const { currentUser } = useAuth();
  const FID = currentUser.photoURL;
  const [VerguetungID, setVerguetungID] = useState(formdata.key);
  const [isAktiv, setisAktiv] = useState(true);
  const db = getDatabase();
  const [userOption, setuserOption] = useState();
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3" className="text-center">
        Verknüfung mit einen Fahrer
      </Popover.Header>
      <Popover.Body>
        Hier die Fahrer oder den Fahrer auswählen der diese Tour Fährt. Dies
        soll falsch eingaben der Fahrer vermeinden.
        <br />
        <br />
        Die Fahrer werden, wenn Sie mehrer Touren zur auswahl haben, beim Start
        der App nach Ihrer heuten Tour gefragt.
        <br />
        <br />
        Wenn der Fahrer nur eine Tour hat, wird diese Abfrage übersprungen, so
        das der Fahrer die Ihn vorgegebene Tour Fährt.
        <br />
        <br />
        Welche Tour er Fährt wird Ihn auch in der App angezeigt.
      </Popover.Body>
    </Popover>
  );



  
  
  

  useEffect(() => {
    const optionsUsers = [];
    const dbRef = ref(db, `touren/` + FID);
    return onValue(dbRef, (snap) => {
      if (snap.exists()) {
        const data = snap.val();
        const unsort = Object.keys(data).map((k) => ({
          id: k,
          ...data[k],
        }));
        const resual = unsort.filter((touren) => touren.isAktiv === true);

        resual.forEach(function (touren) {
          optionsUsers.push({ value: touren.id, label: touren.bezeichnung });
        });
        setuserOption(optionsUsers);
        return;
      }
      //setLoading(true)
    });
  }, [db, FID]);
 const [VerguetungData, setFormData] = useState({
    aktiveDate: new Date().getTime(),
    createDate: new Date().getTime(),
    fid: FID,
    isAktiv: true,
    pauschale: "",
    prostopp: "",
    maut: "",
    diselzuschlag: "",
    tourassign: [],
  });
 



  const styles = {
    myCoolButton: {
      paddingRight: "1vw",
    },
  };
 
  const handleChangeisaktiv = (AktivChecked) => {
    setisAktiv(AktivChecked);
    setFormData({ ...VerguetungData, isAktiv: AktivChecked });
  };

  useEffect(() => {
    if (!IsnewVerguetung) {
      setVerguetungID(formdata.key);
      setFormData(formdata.verguetung);
      
    }
  }, [IsnewVerguetung, formdata]);

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => childToParent(e, VerguetungData, VerguetungID)}
        className="mt-3"
      >
        <Card
          className={
            width < 768
              ? "bg-light align-items-stretch myCard"
              : "align-items-stretch myCard"
          }
        >
          <Card.Header className="text-center">
            Angaben über die Vergütung
          </Card.Header>

          <Card.Body className="d-flex flex-column">
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Allgemeine Angaben">
                <Row className="mb-3">
                  <Form.Group as={Col} md="12">
                    <Stack direction="horizontal" gap={3}>
                      <Form.Label>Ist Aktiv</Form.Label>
                      <label>
                        <Switch
                          onChange={handleChangeisaktiv}
                          checked={VerguetungData.isAktiv}
                          offColor="#f0a000"
                          onColor="#00a000"
                          uncheckedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 12,
                                color: "black",
                                paddingRight: 2,
                              }}
                            >
                              Nein
                            </div>
                          }
                          checkedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 12,
                                color: "black",
                                paddingRight: 2,
                              }}
                            >
                              Ja
                            </div>
                          }
                        />
                      </label>
                    </Stack>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md="6"
                    style={styles.myCoolButton}
                    controlId="validationPauschale"
                  >
                    <Form.Label>Pauschale der Vergütung</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        {eurosign}
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="number"
                        min="0.00"
                        step="0.01"
                        placeholder="Pauschale"
                        value={VerguetungData.pauschale || ""}
                        onChange={(event) =>
                          setFormData({
                            ...VerguetungData,
                            pauschale: event.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {" "}
                        Bitte eine Pauschale eingeben!{" "}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>
                        Sieht gut aus!
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    style={styles.myCoolButton}
                    controlId="validationprostopp"
                  >
                    <Form.Label>Vergütung pro Stopp</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        {eurosign}
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="number"
                        min="0.00"
                        step="0.01"
                        placeholder="pro Stopp"
                        value={VerguetungData.prostopp || ""}
                        onChange={(event) =>
                          setFormData({
                            ...VerguetungData,
                            prostopp: event.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {" "}
                        Bitte einen betrag eingeben!{" "}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>
                        Sieht gut aus!
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md="6"
                    style={styles.myCoolButton}
                    controlId="validationMaut"
                  >
                    <Form.Label>Pauschale für Maut</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        {eurosign}
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="number"
                        min="0.00"
                        step="0.01"
                        placeholder="Maut"
                        value={VerguetungData.maut || ""}
                        onChange={(event) =>
                          setFormData({
                            ...VerguetungData,
                            maut: event.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {" "}
                        Bitte eine Pauschale für die Maut eingeben!{" "}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>
                        Sieht gut aus!
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    style={styles.myCoolButton}
                    controlId="validationdiesel"
                  >
                    <Form.Label>Diselzuschlag</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        {percentsign}
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="number"
                        min="0.00"
                        step="0.01"
                        placeholder="Prozent"
                        value={VerguetungData.diselzuschlag || ""}
                        onChange={(event) =>
                          setFormData({
                            ...VerguetungData,
                            diselzuschlag: event.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {" "}
                        Bitte einen prozentualen wert eingeben!{" "}
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>
                        Sieht gut aus!
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationTour"
                    style={styles.myCoolButton}
                  >
                    <Form.Label>
                      welche Tour soll die Vergütung bekommen
                    </Form.Label>

                    <Select
                      isMulti
                      options={userOption}
                      value={VerguetungData.tourassign || ""}
                      onChange={(e) => {
                        setFormData({ ...VerguetungData, tourassign: e });
                      }}
                    />
                  </Form.Group>
                </Row>
              </Tab>
            </Tabs>
          </Card.Body>
          <Card.Footer>
            <div className="row">
              <div className="col d-grid gap-2 mt-auto">
                <Button
                  variant="outline-danger "
                  onClick={() => {
                    setShowNewVerguetung(false);
                  }}
                >
                  Abbrechen
                </Button>
              </div>
              <div className="col d-grid gap-2 mt-auto">
                <Button
                  className="float-right"
                  variant="outline-success"
                  type="submit"
                >
                  {IsnewVerguetung ? "Speichern" : "Update"}
                </Button>
              </div>
            </div>
          </Card.Footer>
        </Card>
      </Form>
    </>
  );
};
