import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import {
  ref,
  getDatabase,
  push,
  child,
  update,
  onValue,
} from "firebase/database";
 import { useAuth } from "../../../context/authContext";
 import {
  Card,
  Tab,
  Row,
  Form,
  Col,
  Button,
  ListGroup,
  Badge,
  Stack,
  Container,
} from "react-bootstrap";
import toast from "react-hot-toast";
import useWindowDimensions from "../../useMediaQuery";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Menu, Item, theme, useContextMenu } from "react-contexify";
import InfoModal from "../../../InfoModal";
import { VerguetungForm } from "./VerguetungForm";
import { numberFormat } from '../../../utils/numberFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRoad } from '@fortawesome/free-solid-svg-icons'


export const Verguetung = () => {
 const { currentUser ,isAdmin} = useAuth();
 const [FID, setFID] = useState("");
 const navigate = useNavigate();
 const { width } = useWindowDimensions();
 const [VerguetungData, SetVerguetungData] = useState({
  tour: null,
  key: null,
});
 const isMobile = width < 768;
 const [IsnewVerguetung, setIsnewVerguetung] = useState(true);
 const [showNewVerguetung, setShowNewVerguetung] = useState(false);
 const [loadingVerguetung, setloadingVerguetung] = useState([]);
 const [Loading, setLoading] = useState(true);
 const db = getDatabase();
 const [ConfirmMenu, setConfirmMenu] = useState([]);
 const [showInfoModal, setshowInfoModal] = useState(false);
 const MENU_ID = "menu-id";
 const { show } = useContextMenu({
  id: MENU_ID,
});

useEffect(() => {
  if (!isAdmin) {
    navigate(-1)
    
  }
}, [isAdmin, navigate]);


const roadsign = <FontAwesomeIcon icon={faRoad} />;

 function displayMenu(e, props) {  
  show(e, props);  
}

useEffect(() => {
  const dbRef = ref(db, `verguetung/` + FID);
  setLoading(true);
  return onValue(dbRef, (snap) => {
    if (snap.exists()) {
      const data = snap.val();
      const unsort = Object.keys(data).map((k) => ({
        id: k,
        ...data[k],
      }));
      //const resual = unsort.filter((user) => user.islkw === fzart);
      setloadingVerguetung(unsort);
      setLoading(false);
      return;
    } else {
      setloadingVerguetung([]);
      setLoading(false);
    }
  });
}, [Loading, db, FID]);

useEffect(() => {
  if (currentUser) {
    setFID(currentUser.photoURL);
  }
}, [currentUser]);

function isItemDisabled({ props }) {  
  return props.isAktiv;
}

const handleMenuItemClick = async ({ event, props }) => {
  setConfirmMenu(props);
  switch (event.currentTarget.id) {
    case "remove":
      setshowInfoModal(true);
      break;
    case "assign":
      break;
    case "email":
      //logic to send email
      break;
    case "sponsor":
      //logic to open sponsor page
      break;
    default:
      break;
  }
};

const deleteVerguetungbyUID = (verguetungid) => {
  const updates = {};
  updates["/verguetung/" + FID + "/" + verguetungid] = null;
  return update(ref(db), updates);
};
const UpdateTour = (data) => {
  //console.log("DATA.KEY => " , data.id);
  const toastId = toast.loading("Bitte Warten Update...");
  const updates = {};
  updates["/verguetung/" + FID + "/" + data.id] = data;
  update(ref(db), updates)
    .then(() => {
      toast.success("Vergütung wurde Aktualisiert");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      toast.error(
        "Es ist ein fehler beim Update aufgeteten\n" +
          errorCode +
          "\n" +
          errorMessage
      );
    })
    .finally(() => {
      toast.dismiss(toastId);
      setShowNewVerguetung(false);
    });
};

const SaveNewTour = (data) => {
  const toastId = toast.loading("Bitte Warten Speicher...");
  const newVerguetungKey = push(child(ref(db), "verguetung/" + FID)).key;
  const updates = {};
  updates["/verguetung/" + FID + "/" + newVerguetungKey] = data;
  update(ref(db), updates)
    .then((fz) => {
      setShowNewVerguetung(false);
      toast.success(
        "Vergüttung wurde erfolgreich angelegt!"
      );
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      toast.error(
        "Es ist ein fehler beim Speichern aufgeteten\n" +
          errorCode +
          "\n" +
          errorMessage
      );
    })
    .finally(() => {
      toast.dismiss(toastId);
    });
};

const handleConfirmation = async () => {
  const toastId = toast.loading("Bitte warten bin beim Löschen...");
  try {
    await deleteVerguetungbyUID(ConfirmMenu.id);
    toast.success("Vergütung wurde gelöscht");
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    toast.error(
      "Es ist ein fehler beim Löschen aufgeteten\n" +
        errorCode +
        "\n" +
        errorMessage
    );
  } finally {
    setConfirmMenu([]);
    toast.dismiss(toastId);
    setShowNewVerguetung(false);
    setshowInfoModal(false);
  }
};

const handleDecline = (e) => {
  e.preventDefault();
  setshowInfoModal(false);
};

const childToParent = (e, data) => {
  const form = e.currentTarget;
  if (form.checkValidity() === false) {
    e.preventDefault();
    e.stopPropagation();
    toast.error("Es sind nicht alle Felder korrekt ausgefüllt");
  } else {
    e.preventDefault();

    if (!IsnewVerguetung) {
      //UpdateFZ
      UpdateTour(data);
    } else {
      //NewFZ
      SaveNewTour(data);
    }
  }
};

const handleClickVerguetung = (data, key) => {
  console.log(key);
  SetVerguetungData({
    verguetung: data,
    key: key,
  });
  setIsnewVerguetung(false);
  setShowNewVerguetung(true);
};

const handleClick = (e) => {
  e.preventDefault();

  SetVerguetungData({
    user: null,
    key: null,
  });

  setIsnewVerguetung(true);
  setShowNewVerguetung(true);
};



  return (
    <>
     {currentUser ? (
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} lg={3} md={4}>
            {!isMobile || !showNewVerguetung ? (
              <Card className="mt-3 bg-light align-items-stretch myCard">
                <Card.Header className=" d-flex justify-content-between align-middle ">
                  <div>Vergütungen</div>
                </Card.Header>
                <Card.Body className="d-flex flex-column">
                  <Tab.Container id="TESTID">
                    <Row>
                      <Col sm={12}>
                        <ListGroup>
                          <Scrollbars
                            autoHeight
                            autoHeightMax="calc(100vh - 230px)"
                          >
                            {loadingVerguetung.map((verguetung) => (
                              <ListGroup.Item
                               className="my-2 d-inline-block d-flex justify-content-between align-items-start"
                                key={verguetung.id}
                                id={verguetung.id}
                                action
                                onContextMenu={(e) => {
                                  displayMenu(e, {
                                    props: {
                                      id: verguetung.id,
                                      isAktiv: verguetung.isAktiv,
                                    },
                                  });
                                }}
                                onClick={() => {
                                  handleClickVerguetung(verguetung, verguetung.id);
                                }}
                              >
                             
                              Pauschale: {numberFormat(verguetung.pauschale)} <br/>
                             
                              Pro Stopp: {numberFormat(verguetung.prostopp)} <br/>
                            
                              Maut: {numberFormat(verguetung.maut)} <br/>
                             
                              Diselzuschlag: {`${verguetung.diselzuschlag}`} %
                            
                                  <Stack
                                direction="horizontal"
                                className="ms-auto"
                                gap={3}
                              >                               
                                {verguetung.tourassign ? (                                  
                                  <span><Badge bg="success" pill style={{width:50}}>
                                  {roadsign} {verguetung.tourassign.map((Tour) =>(
                                  <div key={Tour.value}>
                                    {Tour.label}
                                  </div>

                                  ))} 
                                  </Badge></span>
                                ) : (
                                  <Badge bg="danger" pill style={{width:50}}>
                                  {roadsign}<span> </span>
                                  </Badge>
                                )}       
                                {verguetung.isAktiv ? (
                                  <Badge bg="success" style={{width:60}}>
                                    Aktiv
                                  </Badge>
                                ) : (
                                  <Badge bg="danger" style={{width:60}}>
                                    Deaktiv
                                  </Badge>
                                )}                                       
                              </Stack>
                              </ListGroup.Item>
                            ))}
                          </Scrollbars>
                        </ListGroup>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Card.Body>
                <Card.Footer>
                  <div className="d-grid gap-2 mt-auto">
                    <Button variant="outline-success" onClick={handleClick}>
                      Neue Vergütung hinzufügen
                    </Button>
                  </div>
                </Card.Footer>
              </Card>
            ) : (
              <></>
            )}
          </Col>
          {showNewVerguetung ? (
            <Col xs={12} sm={12} lg={9} md={8}>
              <VerguetungForm
                childToParent={childToParent}
                setShowNewVerguetung={setShowNewVerguetung}
                formdata={VerguetungData}
                IsnewVerguetung={IsnewVerguetung}
              />
            </Col>
          ) : (
            <></>
          )}
        </Row>
     
      <Menu id={MENU_ID} theme={theme.dark}>
        <Item
          id="remove"
          disabled={isItemDisabled}
          hidden={false}
          onClick={handleMenuItemClick}
        >
          Löschen
        </Item>
      </Menu>
      <InfoModal
        bodytext={"Sind Sie sich sicher das Sie die Vergütung löschen möchten?"}
        titeltext={"Bestätigung zum Löshen"}
        handleok={handleConfirmation}
        onHide={handleDecline}
        show={showInfoModal}
      ></InfoModal>
       </Container>
       ):(
      navigate("/login")
    )}   
    </>
  );
}