import React, { useState, useEffect, useRef } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import GoogleMapReact from "google-map-react";
import { Card, Container, Button, Row } from "react-bootstrap";
import { ref, get, child, getDatabase, onValue } from "firebase/database";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import MyMarker from "../live/myMarker";
import "../live/styles.css";
import { Cube } from "react-preloaders2";
import isEmpty from 'lodash.isempty';


const LiveMap = () => {
  const { currentUser, isAdmin } = useAuth();
  const [stoppsdata, setStoppsData] = useState([]);
  const [userPosdata, setuserPosdata] = useState([]);

  const [FID, setFID] = useState("");
  const navigate = useNavigate();
  const [stopps, SetStopps] = useState([]);
  const [touren, setTouren] = useState([]);
  const [userPos, setUserPos] = useState([]);
  const [userAnwesend, setUserAnwesend] = useState([]);
  const [movement, setMovement] = useState([]);
  

  const [dataLoad, setDataLoad] = useState(true);
  const [loadTouren, setLoadTouren] = useState(true);
  const [loadStopps, setLoadStopps] = useState(true);
  const [loadUserPos, setLoadUserPos] = useState(true);
  const [loadUserAnwesend, setLoadUserAnwesend] = useState(true);

  const db = getDatabase();
  const [milltoDay, setMilltoDay] = useState(
    new Date().setUTCHours(0, 0, 0, 0)
  );
  

  useEffect(() => {
    if (currentUser) {    
      setFID(currentUser.photoURL);
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (!isAdmin) {
      navigate("/Dashbord", { replace: true });
    }
  }, [isAdmin, navigate]);
  const defaultProps = {
    center: {
      lat: 53.498985,
      lng: 9.974782,
    },
    zoom: 15,
  };

  //TOuren Laden
  useEffect(() => {    
    if (FID) {
      get(child(ref(getDatabase()), `touren/` + FID))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            const unsort = Object.keys(data).map((k) => ({
              id: k,
              ...data[k],
            }));
            const resual = unsort.filter((tour) => tour.isAktiv === true);          
            setTouren(resual);
            setLoadTouren(false);
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [FID]);
//Stopps Laden
  useEffect(() => {
    
    onValue(
        ref(db, `dailybusiness/` + FID + "/" + milltoDay + "/anwesenheit"),
        (data) => {
      
          setUserAnwesend(data.val());
          setLoadUserAnwesend(false);
        }
      );
      onValue(
        ref(db, `dailybusiness/` + FID + "/" + milltoDay + "/stopps"),
        (data) => {
          SetStopps(data.val());
          setLoadStopps(false);
        }
      );

      onValue(
        ref(db, `movement/` + FID + "/" + milltoDay),
        (data) => {      
          setMovement(data.val());
        }
      );


      onValue(
        ref(db, `currentPos/` + FID + "/" + milltoDay ),
        (data) => {
            setUserPos(data.val());
            setLoadUserPos(false);
        }
      );
   
  }, [FID,  db, milltoDay]);
//Stopps aufbereiten
  useEffect(() => {
    if (!isEmpty(stopps) && !isEmpty(touren)) {     
      let count = 0;      
      
      setStoppsData([]);
      Object.entries(stopps).forEach(([key, value]) => {

        const tour = touren.filter((x) => x.id === value.tourid);
       

        setStoppsData((stoppsdata) => [
          ...stoppsdata,
          {
            key: key,
            lat: value.latLng.latitude,
            lng: value.latLng.longitude,
            text: ++count,
            data: value,
            tour: tour,
            movement: null,
          },
        ]);
      });

      setDataLoad(false);
    }
  }, [loadStopps, stopps, touren]);
// Current Pos aufbereiten
  useEffect(() => {
    if (!isEmpty(userPos)) {     
      
        let count = 0;
        setuserPosdata([]);
        Object.entries(userPos).forEach(([key, value]) => {
        try{

        
        const userAn = Object.entries(userAnwesend).filter((xkey) => xkey[0] === key)

        const move = Object.entries(movement).filter((xkey) => xkey[0] === key)
        //console.log(userAn)
      
          if(userAn.length > 0 && !isEmpty(move)){
              setuserPosdata((userPosdata) => [
              ...userPosdata,
              {
                  key: userAn[0][0],
                  lat: value.you.l[0],
                  lng: value.you.l[1],
                  data: userAn[0][1],
                  text: ++count,
                  tour: null,
                  movement: move[0][1].movement,
              },
              ]);
          }
          }catch{

          }
        });
      //  console.log("userPos=>",userPosdata)  
        setDataLoad(false);
    }
  }, [movement, userAnwesend, userPos]);
 



  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();   
    places.forEach((place) => {        
      bounds.extend(new maps.LatLng(
        place.lat,
        place.lng,
      ));
    });
    return bounds;
  };

// Re-center map when resizing the window
    const bindResizeListener = (map, maps, bounds) => {
        window.addEventListener('resize', (event) => { 
            event.preventDefault()
            map.fitBounds(bounds);
        });
    };

  const handleApiLoaded = (map, maps) => { 
  

    const bounds = getMapBounds(map, maps, userPosdata);
   
    map.fitBounds(bounds);
    bindResizeListener(map, maps, bounds);
  };

  const onStoreMarkerClick =(Key,Data) => {
    console.log("Key=>",Key);
    console.log("Data=>",Data);
   

  }

  return (
    <>
      {!isEmpty(userPosdata) || !isEmpty(stoppsdata) ? (
      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onChildClick={onStoreMarkerClick}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
        {!isEmpty(userPosdata) ?(
          userPosdata.map(({ lat, lng, key, data, tour, movement }) => {
                          return (
                          <MyMarker
                              key={key}
                              uid={key}
                              lat={lat}
                              lng={lng}
                              data={data}   
                              tour={tour}     
                              movement={movement}                                 
                          >
                          
                          </MyMarker>


                          );
          })
          ):(<></>)}
         
        {!isEmpty(stoppsdata) ?(
        stoppsdata.map(({ lat, lng, key, data, tour,movement}) => {
            return (
              <MyMarker
                key={key}
                uid={key}
                lat={lat}
                lng={lng}
                data={data}  
                tour={tour}
                movement={movement}             
              />
            );
        })
        ):(<></>)}

        </GoogleMapReact>
      </div>
      ):(<Cube customLoading={dataLoad} time={0} background="#40bfb2" />)}
      
    
      
    </>
  );
};
export default LiveMap;
