import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTruckRampBox,
    faTruck,
   faOilCan,
} from "@fortawesome/free-solid-svg-icons";
import isEmpty from "lodash.isempty";

const MyMarker = ({ uid, data , tour,movement}) => {
    
    const truckRanpBox = <FontAwesomeIcon icon={faTruckRampBox} />;
    const truckbounce = <FontAwesomeIcon icon={faTruck}  bounce    />;
    const truck = <FontAwesomeIcon icon={faTruck}  />;
    const OilWell = <FontAwesomeIcon  icon={faOilCan} />;
    // console.log(data);
    const handleClick = (e) => {
        e.preventDefault();
        //  console.log(`You clicked on ${tooltip}`);
    };
    const handleLKWClick = (e) => {   
        e.preventDefault();
      
     
    };

    const handleLKWMouseOver = (e) => { 
        e.preventDefault();
      
    }  
    const handleLKWMouseExit = (e) => { 
        e.preventDefault();
       
    }  


    if (!isEmpty(data.art)) {
        return (
            <div className={"marker"} onClick={handleClick}>

                <span className={data.art !== "erfolgreich" ? "rot" : "gruen"}>
                <b>
                    <div>{(tour[0].bezeichnung)}</div>
                    <div className="text-center">{truckRanpBox}</div>                  
                </b>                   
                </span>
            </div>

        );
    } else {
        return (
            <div className="marker" style={{ position: 'absolute', zIndex: 1 }} onClick={handleLKWClick} onMouseOver= {handleLKWMouseOver} onMouseOut={handleLKWMouseExit}>
                <span 
                    className=  {
                        !data.tagesabschluss
                            ? data.beentladepunkt
                                ? "blau"
                                : "gelb"
                            : "feierabend"
                    }
                >
                    <b>
                        <div>{data.Tour}</div>
                        <div className="text-center">{data.tagesabschluss ? truck : movement ? truckbounce : truck }</div>
                    </b>
                </span>
            </div>
        );
    }
};

export default MyMarker;
