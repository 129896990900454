import React, { useState, useEffect } from "react";

import gesterlingKopf from "../../images/kopfzeile.png";
import gesterlingFuss from "../../images/Fusszeile.png";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image, 
} from "@react-pdf/renderer";
import {Table,TableHeader,TableCell,TableBody,DataTableCell } from "@david.kucsai/react-pdf-table"
import moment from "moment-feiertage";



// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 15,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  table: {
    paddingTop: 15,   
  },
  tableheader: {
    fontSize: 12,   
    textAlign: "center",    
  },
  header: {
    fontSize: 20,
    paddingTop: 20,
    marginBottom: 20,
    textAlign: "center",    
  },
  image: {
    marginVertical: 0,
    marginHorizontal: 0,
    width: "auto",
  },
  
  imageFuss: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    width: "auto",
  },
  title: {
    fontSize: 18,
    textAlign: "center",
  },
  spesen:{
    paddingTop: 3,
    paddingBottom: 3,
    textAlign: "right",
    fontSize: "10", 
  },
  gesamtspesen:{
    fontSize: 12,
    paddingRight: 2,
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: "right",  
    backgroundColor: "lightgreen", 
  }, 
  gesamtarbeitszeit:{
    fontWeight: 'bold',
    fontSize: 12,
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: "center",  
    backgroundColor: "lightgreen", 
  },
  footername:{    
    fontSize: 12,
    paddingLeft: 2,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: "lightgreen", 
  }, 

  tabledata:{
    paddingTop: 3,
    paddingBottom: 3,
    textAlign: "center",  
    fontSize: "10", 
  },
  tableCell:{
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: "center",   
    fontSize: "12",
    backgroundColor: "lightblue",
   
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
    showToolbar: false,
  },
  totalNumber: {
    position: 'absolute',
    fontSize: 16,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

function BasicDocument({ data ,userinfo, key}) {
  
  const [loadingdata, setloadingdata] = useState(false);
  
  useEffect(() => {
    if (data !== null && data.length !== 0 && userinfo !== null && userinfo.length !== 0) {    
      
     


        setloadingdata(true);
    }
  }, [data,userinfo]);

  const formatDateMonthLongYearNum = (timestamp) => {
    return new Intl.DateTimeFormat("de-DE", {
      month: "long",
      year: "numeric",
    }).format(timestamp);
  };

  const currencyNumberFormat = (value) => {
    return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(value)       
  }

 


  const formatTime = (timestamp) => {
    const timeOptions = {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      
    };

    return new Intl.DateTimeFormat('de-DE', {
      timeZone: 'Europe/Berlin', ...timeOptions
    }).format(timestamp);
  
  };
  const formatDate = (timestamp) => {
    return new Intl.DateTimeFormat("de-DE", {
      day: '2-digit',
      month: "2-digit",
      year: "numeric",
    }).format(timestamp);
  };

const milltoHHMMSS = (Mills) =>{
  var secends = moment.duration(Mills).seconds();
  var minutes = moment.duration(Mills).minutes();
  var hours = Math.trunc(moment.duration(Mills).asHours());
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (hours < 10) {
    hours = '0' + hours;
  }
  return hours + ':' + minutes 

}
  

  
  var spesen = data.map(function(data, index){
    const total = [];
    const userID = data[0].id
   
    const myUser = userinfo.filter((user) => user.id === userID)
    //console.log(data)



    total.push({
        spesen: data.reduce((prevValue, currentValue) => prevValue + currentValue.spesen,0),
        arbeitszeit:data.reduce((prevValue, currentValue) => prevValue + currentValue.stunden,0),
        name:myUser[0]['name'],
        });
      
    return (
        <>
        
        <Page style={styles.body} size="A4" >
            <View   style={[styles.row, {fontSize:10}]}>
            <Image style={styles.image} src={gesterlingKopf}/>            
            <Text style={styles.header}>Spesenabrechnung für {formatDateMonthLongYearNum(data[0]['beginn'])}</Text>             
           

            <View style={[styles.box]}>            
              <Text style={[styles.text, {}]}>{myUser[0]['name']}</Text>
              <Text style={[styles.text, {}]}>{myUser[0]['strasse']}</Text>
              <Text style={[styles.text, {}]}>{myUser[0]['plz']} {myUser[0]['ort']}</Text>
            </View>

            <View  style={[styles.table]}>
                <Table
                 data={data}
                >
              
                    <TableHeader fontSize={"10"}>
                        <TableCell style={styles.tableCell} >
                               Datum
                        </TableCell>
                        <TableCell style={styles.tableCell} >
                            Arbeitsbeginn 
                        </TableCell>
                        <TableCell style={styles.tableCell} >
                            Pause
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            Feierabend 
                        </TableCell>
                        <TableCell style={styles.tableCell} >
                            Arbeitsstunden
                        </TableCell>
                        <TableCell style={styles.tableCell} >
                            Spesen
                        </TableCell>
                    </TableHeader>
                  
                    <TableBody>
                      
                        <DataTableCell   getContent={(r) => formatDate(r.datum)} style={styles.tabledata}/>
                        <DataTableCell   getContent={(r) => formatTime(r.beginn)} style={styles.tabledata}/>
                        <DataTableCell   getContent={(r) => milltoHHMMSS(r.pause)} style={styles.tabledata}/>
                        <DataTableCell   getContent={(r) => formatTime(r.feierabend)} style={styles.tabledata} />
                        <DataTableCell   getContent={(r) => milltoHHMMSS(r.stunden)} style={styles.tabledata}/>
                        <DataTableCell   getContent={(r) => currencyNumberFormat(r.spesen)} style={styles.spesen}/>
                       
                    </TableBody>                   
                </Table>
                <View >
                    <Table 
                    data={total}
                    >
                     <TableBody >
                        <DataTableCell  getContent={(r) => "Spesen für " + r.name} style={styles.footername} />
                        <DataTableCell  getContent={(r) => "Gesamt Arbeitszeit: " + milltoHHMMSS(r.arbeitszeit)} style={styles.gesamtarbeitszeit} />
                        <DataTableCell getContent={(r) => "Gesamt Spesen: " + currencyNumberFormat(r.spesen)} style={styles.gesamtspesen} />
                     </TableBody>
                    
                </Table>
                </View>
            </View>
           
          </View>
          <Image style={styles.imageFuss} src={gesterlingFuss}/>   
          </Page>
          
         
        </>
        );
  });

  return (
    <>
      {loadingdata ? (      
      
        <Document>
            {spesen}              
        </Document>
       
      ) : (
        <>  <Document></Document></>
      )}
    </>
  );
}
export default BasicDocument;
