import React, { useState,useEffect  } from "react";
import {  Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope} from '@fortawesome/free-solid-svg-icons'
import './noticebutton.css';
import {
  ref,
  getDatabase,  
  onValue,
 
} from "firebase/database";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";

function NoticeButton() {   
  const { currentUserDetail,currentUser } =  useAuth();
  const [countNotice,setcountNotice] = useState(0);
  const [error,setError] = useState("");
  const [FID,setFID] = useState(null);
  const [UID,setUID] = useState(null);
  const db = getDatabase();
  const navigate = useNavigate();





  useEffect(() => {
    
    if(currentUserDetail !==null && currentUserDetail !==undefined){
       
       const Noticeref = ref(db, "notice/" + currentUserDetail.fid + "/" + currentUser.uid );
      onValue(Noticeref, (snapshot) => {
        if(snapshot.exists){
           if(snapshot.val() !== null){
            setcountNotice(Object.entries(snapshot.val()).length)
          }else{
            setcountNotice(0)
          }
        }else{
          setcountNotice(0)
        }
         
      });
    }
  },[currentUserDetail,currentUser, db]);

 

const handleLogout = async () => {
  setError("");
  try {   
    navigate("/notice", { replace: true });
  } catch(error){
    console.log(error)
  }
}

return(
  <>
   <Button className="fas fa-lg" data-count={countNotice}  onClick= {handleLogout}>
    <FontAwesomeIcon icon={faEnvelope} />
   </Button>
</>

  
 
 
  

 
    
)


}

export default NoticeButton;