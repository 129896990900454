import React, { useState  } from "react";
import {  Button } from "react-bootstrap";
import {useAuth}from "../../../context/authContext"
import { useNavigate } from "react-router-dom"
import toast from "react-hot-toast";




function LoginButton() {   
  
    const {currentUser, logout} =  useAuth();
    const [error,setError] = useState("");


const handleLogout = async () => {
  setError("");
  try {
    await logout();
   
  } catch(error){
    toast.error(currentUser.displayName + '\nkonnte nicht abgemeldet werden!\n' + error.message ,{
      style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
    });
  }
}

return(
  
     <>
          {currentUser ? 
          <Button            
            variant= 'outline-light'
            onClick= {handleLogout}
          >
            {currentUser ? 'Ausloggen' : 'Einloggen'}
          </Button>    
        :''}    
   </>
)


}

export default LoginButton;