import React from 'react';
import '../../App.css';

export default function Services() {
    return (
        <>
          <h1 className='service'>SERVICES</h1>
        </>
      );
  
}