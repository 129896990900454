import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  InputGroup,
  Card,
  Form,
  Col,
  Row,
  Button,
  Tabs,
  Tab,
  OverlayTrigger,
  Popover,
  Stack,
  Modal,
} from "react-bootstrap";
import TimePicker from "react-bootstrap-time-picker";

import  { registerLocale } from "react-datepicker";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

import de from "date-fns/locale/de";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfYear,
  startOfMonth,
  endOfMonth,
  format,
  endOfYear,
  addMonths,
  addYears,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
  differenceInBusinessDays,
  eachDayOfInterval,
  addWeeks,
} from "date-fns";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";
import Moment, * as momentHolly from "moment-feiertage";
import * as locales from "react-date-range/dist/locale";
import "../anwesenheit/calender.css";
import {
  ref,
  get,
  getDatabase,
  onValue,
  child,
  orderByKey,
  equalTo,
  update,
  startAt,
  endAt,
  query,
} from "firebase/database";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash.isempty";
import { confirmAlert } from "react-confirm-alert"; // Import
import useWindowDimensions from "../../useMediaQuery.js";
import Anwesheitclickday from "../anwesenheit/anwesheitclickday";
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  Menu,
  Item,
  theme, 
  useContextMenu
} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

registerLocale("de", de);

function workingDaysBetweenDates(d0, d1) {
  var mydays = 0;

  while (d0 <= d1) {
    if (
      !momentHolly(d0).isHoliday("HH") &&
      d0.getDay() > 0 &&
      d0.getDay() < 6
    ) {
      mydays++;
    }

    var newDate = d0.setDate(d0.getDate() + 1);
    d0 = new Date(newDate);
  }
  return mydays;
}

const MENU_ID = "menu-id";






const AnwesenheitUser = ({
  User,
  Users,
  fahrzeuge,
  touren,
  childToParent,
  setShowAnweseheitUser,
}) => {
  const { currentUser, isAdmin } = useAuth();
  const [FID, setFID] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const db = getDatabase();
  const [arbeitstageuser, setArbeitstageuser] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [arbeitstage, setArbeitstage] = useState(0);
  const [KalenderEintraege, SetKalenderEintraege] = useState([]);

  const nextMonth = addMonths(new Date(), 1);
  const [month, setMonth] = useState(new Date());
  const [workingDays, setWorkingDays] = useState([]);
  const [sickDays, setSickDays] = useState([]);
  const [SickDaysCount, setSickDaysCount] = useState(0);

  const [holiDays, setHoliDays] = useState([]);
  const [fzoption, setfzoption] = useState([]);
  const [touroption, setTourOption] = useState([]);
  const [usersoption, setUsersOption] = useState([]);
  const spesenoption = [
    { key: true, Label: "ja" },
    { key: false, Label: "nein" },
  ];
  const timeZone = "Europe/Berlin";

  const [currentEvent, setCurrentEvent] = useState(null);
  const [newEvent, setNewEvent] = useState(null);
  const [day, setDay] = useState();
  const [footer, setFotter] = useState("");
  const { height, width } = useWindowDimensions();
  const isMobile = width < 768;
  const { show } = useContextMenu({
    id: MENU_ID
  });


  function displayMenu(e, props) {
     console.table(props)
    // pass the item id so the `onClick` on the `Item` has access to it
    show(e, props);
    // show(e, { props: { id: Number(e.currentTarget.id) } });
    // console.table(e)
  }
  function isItemDisabled({ props, data, triggerEvent }) {
    // use the parameters to determine if you want to disable the item or not
    // you get the idea
    return props.isDelete !== true;
  }

  useEffect(() => {
    if (currentUser) {
      setFID(currentUser.photoURL);
    }
  }, [currentUser, navigate]);
  useEffect(() => {
    if (!isAdmin) {
      navigate("/Dashbord", { replace: true });
    }
  }, [isAdmin, navigate]);

  useEffect(() => {

    if (!isEmpty(data)) {
      setCurrentEvent(null)
      setArbeitstageuser([]);
      Object.values(data).filter((element, index, array) => {
        Object.entries(array[index]["anwesenheit"]).filter(
          (element, index, array) => {
            // console.log("array=>",Object.values(array))
            Object.values(array)
              .filter((key) => User.id.includes(key[index]))
              .reduce((obj, key) => {
                setArbeitstageuser((arbeitstage) => [...arbeitstage, key[1]]);
              }, {});
          }
        );
      });
    }
  }, [User.id, data]);




  useEffect(() => {
    setArbeitstage(
      workingDaysBetweenDates(startOfMonth(month), endOfMonth(month))
    );
    const getData = (start, ende) => {
      const queryConstraints = [orderByKey(), startAt(start), endAt(ende)];
      get(query(ref(db, "dailybusiness/" + FID), ...queryConstraints)).then(
        (data) => {
          if (data.exists()) {
            setData(data.val());
          } else {
            setWorkingDays([]);
            setArbeitstageuser([]);
            setSickDaysCount(0);
          }
        }
      );
    };
    getData(
      String(startOfMonth(month).setHours(0, 0, 0, 0)),
      String(endOfMonth(month).setHours(23, 59, 59, 0))
    );
  }, [FID, db, month]);

  useEffect(() => {
    setWorkingDays([]);
    arbeitstageuser.forEach((key) => {
      setWorkingDays((workingDays) => [...workingDays, new Date(key.beginn)]);
    });
  }, [arbeitstageuser]);

  useEffect(() => {
    if (isEmpty(fzoption)) {
      Object.entries(fahrzeuge).map(([key, value]) => {
        setfzoption((fzoption) => [
          ...fzoption,
          {
            id: value.id,
            kennzeichen: value.kennzeichen,
          },
        ]);
      });
    }
  }, [fahrzeuge, fzoption]);

  useEffect(() => {
   
    if (isEmpty(usersoption)) {
      Object.entries(Users).map(([key, value]) => {
        setUsersOption((usersoption) => [
          ...usersoption,
          {
            id: value.id,
            name: value.name,
          },
        ]);
      });
    }
  }, [Users, usersoption]);

  useEffect(() => {
    setFotter(
      <>
        Mögliche Arbeitstage: {arbeitstage} <br />
        Gearbeitet: {arbeitstageuser.length}
      </>
    );
  }, [SickDaysCount, arbeitstage, arbeitstageuser.length, sickDays]);

  useEffect(() => {
    if (isEmpty(touroption)) {
      Object.entries(touren).map(([key, value]) => {
        setTourOption((touroption) => [
          ...touroption,
          {
            id: value.id,
            bezeichnung: value.bezeichnung,
          },
        ]);
      });
    }
  }, [touren, touroption]);

  useEffect(() => {
    const dbRef = ref(db, `kalender/` + FID);
    setSickDays([]);
    setHoliDays([]);

    return onValue(
      dbRef,
      (snap) => {
        const KData = snap.val();

        if (!isEmpty(KData)) {
          const UserKDat = Object.values(KData).filter(
            (x) => x.uid === User.id && x.year === month.getFullYear()
          );

          if (!isEmpty(UserKDat)) {
            const UserKDateMonat = Object.values(UserKDat).filter(
              (x) => x.anlassid === "1"
            );
            const UserUDateMonat = Object.values(UserKDat).filter(
              (x) => x.anlassid === "0"
            );
            if (!isEmpty(UserKDateMonat)) {
              var KArray = [];
              setSickDaysCount(0);
              KArray = Object.entries(UserKDateMonat).map(([key, value]) => {
                return eachDayOfInterval({
                  start: new Date(value.startDate),
                  end: new Date(value.endDate),
                });
              });
              setSickDays(Object.values(KArray).flat());
            } else {
              setSickDays([]);
            }
            if (!isEmpty(UserUDateMonat)) {
              var UArray = [];
              UArray = Object.entries(UserUDateMonat).map(([key, value]) => {
                return eachDayOfInterval({
                  start: new Date(value.startDate),
                  end: new Date(value.endDate),
                });
              });
              setHoliDays(Object.values(UArray).flat());
            } else {
              setHoliDays([]);
            }
          }
        } else {
          setSickDays([]);
          setHoliDays([]);
        }
      },
      {
        onlyOnce: true,
      }
    );
  }, [FID, User.id, db, month]);

  const handleDayClick = (day, modifiers) => {
    setDay(day);
  //  console.log("modifiers=>",modifiers)
  //  console.log("day=>",day.getTimezoneOffset())

    if (modifiers.working) {
      const millesek = utcToZonedTime(day, timeZone).setHours(0, day.getTimezoneOffset()*-1, 0, 0);
      const queryConstraints = [orderByKey(), equalTo(User.id)];
  //    console.log("day=>", millesek);

      get(
        query(
          ref(db, "dailybusiness/" + FID + "/" + millesek + "/anwesenheit"),
          ...queryConstraints
        )
      ).then((data) => {
        if (data.exists()) {
          console.log("data.val()=>", data.val());
          setCurrentEvent(data.val())
          //  setData(data.val());
        } else {
          // setWorkingDays([])
          // setArbeitstageuser([])
          // setSickDaysCount(0)
        }
      });
    } else if (modifiers.sick) {
      setCurrentEvent(null)
    //  console.log("sick=>", modifiers.sick);
    } else if (modifiers.holi) {
      setCurrentEvent(null)
    //  console.log("holi=>", modifiers.holi);
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h2>Anwesenheit hinzufügen? </h2>
              <p>
                Möchten Sie für den {format(day, "dd.MM.yyyy")} eine neue
                Anwesenheit für {User.name} hinzufügen?
              </p>
              <div className="d-flex justify-content-evenly">
                <Button variant="outline-primary" onClick={onClose}>
                  Abbrechen
                </Button>
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setNewEvent({
                      beginn: day.setHours(6, 0, 0),
                      end: day.setHours(14, 30, 0),
                    });
                    onClose();
                  }}
                >
                  Ja, das möchte ich!
                </Button>
              </div>
            </div>
          );
        },
      });
    }
  };
  const handleStartchange = (time) => {
    const start = newEvent.day.setHours(0, 0, 0, 0) + time;
    setNewEvent({
      ...newEvent,
      beginn: start,
    });
  };
  const handleendchange = (time) => {
    const start = newEvent.day.setHours(0, 0, 0, 0) + time;
    setNewEvent({
      ...newEvent,
      end: start,
    });
  };
  const handlefzchange = (fz) => {
    const filtered = Object.keys(fahrzeuge)
      .filter((key) => fz.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: fahrzeuge[key],
        };
      }, {});

    setNewEvent({
      ...newEvent,
      Fahrzeug: {
        ID: fz,
        Kennzeichen: filtered[fz]["kennzeichen"],
      },
    });
    // console.log(filtered[fz]['kennzeichen'])
  };


  const searchWord = (date,userid) =>{ 
    console.log("meine DAten1=>",data)
   return Object.keys(data)
    .filter(key => date.includes(key))
    .reduce((obj, key) => {
        
      console.log("meine DAten=>",data[key])
     // obj[key] = users[key];
        return data[key];
  }, {});


  /*   Object.keys(data).filter(id => {
        if(Number(id) === Number(date)){
           Object.keys(data[id]['anwesenheit']).filter(UID =>{
            if(UID === userid){
              console.log("meine DAten=>",data[id]['anwesenheit'][UID])
              return "TEller" //data[id]['anwesenheit'][UID]
            }         
          });
        };      
    }); */
  }


  const handleuserschange = (valueUSer) => {

  if (!isEmpty(data)) {
    console.log(valueUSer)
   
    console.log("searchWord=>",searchWord(["1677024000000"],valueUSer))
  
  }
  };

  const handleTourchange = (tourid) => {
    const filtered = Object.keys(touren)
      .filter((key) => tourid.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: touren[key],
        };
      }, {});

    setNewEvent({
      ...newEvent,
      Tour: filtered[tourid]["bezeichnung"],
    });
    // console.log(filtered[fz]['kennzeichen'])
  };

  const handleSpesenchange = (spesen) => {
    setNewEvent({
      ...newEvent,
      spesen: spesen === "true",
    });
  };
  const handleTagesabschlusschange = (tg) => {
    setNewEvent({
      ...newEvent,
      tagesabschluss: tg === "true",
    });
  };
  const handleContextMenu = async ({ event, props }) => {

    switch (event.currentTarget.id) {
      case "remove":
        // logic to remove the row
       // console.table(props); // contain to item.id passed by `show`
       try {
         // await deleteUserbyUID(props.id);
        
      } catch (error) {
        console.table(error);
      } finally {
        
      }

        break;
      case "share":
        // logic to share
        break;
      case "email":
        //logic to send email
        break;
      case "sponsor":
        //logic to open sponsor page
        break;
      default:
        break;
    }
    //console.table(data );
    // console.table(props );
    // console.table(triggerEvent );
    // console.table(event );

  }
  const handleClickButton = (e) => {
    //setShowAnweseheitUser(false)
  };

  return (
    <>
      <Form noValidate onSubmit={(e) => childToParent(false)} className="mt-3">
        <Card className="bg-light align-items-stretch myCard">
          <Card.Header className="text-center">
            Mitarbeiter Anwesenheitsübersicht
          </Card.Header>
          <Card.Body className="d-flex flex-column">
            <Row>
              <Col xs={12} sm={12} lg={3} md={4}>
                <DayPicker
                  modifiers={{
                    working: workingDays,
                    sick: sickDays,
                    holi: holiDays,
                  }}
                  modifiersClassNames={{
                    working: "greenDay",
                    sick: "redDay",
                    holi: "blueDay",
                  }}
                  locale={de}
                  footer={footer}
                  onMonthChange={setMonth}
                  onDayClick={handleDayClick}                 
                 


                />
             


              </Col>
              <Col xs={12} sm={12} lg={9} md={12}>
                {!isEmpty(currentEvent) ? (
                  <div style={{ display: 'flex' }}>
                 
                    <Anwesheitclickday currentEvent={currentEvent} >

                    </Anwesheitclickday>
                 
                  </div>

                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Card.Body>
          {isMobile ? (
            <Card.Footer>
              <div className="col d-grid gap-2 mt-auto">
                <Button
                  variant="outline-danger "
                  onClick={() => {
                    setShowAnweseheitUser(false);
                  }}
                >
                  Zurück
                </Button>
              </div>
            </Card.Footer>
          ) : (
            <></>
          )}
        </Card>
      </Form>
      <Menu id={MENU_ID} theme={theme.dark}>
          <Item id="remove" disabled={isItemDisabled} hidden={false} >
            Entgültig Löschen
          </Item>
      </Menu>      
      <Modal
        show={newEvent}
        onHide={() => setNewEvent(null)}
        //  onEntering={() => ModalEntered()}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        {newEvent && (
          <div>
            <Modal.Header closeButton>
              <Modal.Title>
                Neue Anwesenheit für den {format(day, "dd.MM.yyyy")}
              </Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
            <Row>
              <Form.Group as={Col} md="6" controlId="validationbeginn">
                <Form.Label>Arbeitsbeginn</Form.Label>
                <TimePicker
                  start="04:00"
                  end="09:00"
                  step={15}
                  format={24}
                  className="shadow"
                  initialValue={"21600"}
                  onChange={(time) => handleStartchange(time)}
                />
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="validationbeginn">
                <Form.Label>von anderen Fahrer übernehmen</Form.Label>
                <Form.Control
                  as="select"
                  className="shadow"
                  onChange={(user) => {
                    handleuserschange(user.target.value);
                  }}
                >
                  <option className="d-none"></option>
                  {usersoption.map((option) => (
                    <option value={option.id} key={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              </Row>

              <Form.Group as={Col} md="6" controlId="validationend">
                <Form.Label>Feierabendzeit</Form.Label>
                <TimePicker
                  start="10:00"
                  end="18:00"
                  step={30}
                  format={24}
                  className="shadow"
                  initialValue={"52200"}
                  onChange={(time) => handleendchange(time)}
                />
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="validationend">
                <Form.Label>Fahrzeug</Form.Label>
                <Form.Control
                  as="select"
                  className="shadow"
                  onChange={(fz) => {
                    handlefzchange(fz.target.value);
                  }}
                >
                  <option className="d-none"></option>
                  {fzoption.map((option) => (
                    <option value={option.id} key={option.id}>
                      {option.kennzeichen}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationend">
                <Form.Label>Touren</Form.Label>
                <Form.Control
                  as="select"
                  className="shadow"
                  // disabled={currentEvent.id ? true : false}
                  onChange={(tour) => {
                    handleTourchange(tour.target.value);
                  }}
                >
                  <option
                    className="d-none"
                  //value={currentEvent.uid ? currentEvent.uid : ""}
                  ></option>
                  {touroption.map((option) => (
                    <option value={option.id} key={option.id}>
                      {option.bezeichnung}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationend">
                <Form.Label>Spesen</Form.Label>
                <Form.Control
                  as="select"
                  className="shadow"
                  // disabled={currentEvent.id ? true : false}
                  onChange={(spesen) => {
                    handleSpesenchange(spesen.target.value);
                  }}
                >
                  <option
                    className="d-none"
                  //value={currentEvent.uid ? currentEvent.uid : ""}
                  ></option>
                  {spesenoption.map((option) => (
                    <option value={option.key} key={option.key}>
                      {option.Label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="validationend">
                <Form.Label>Tagesabschluss</Form.Label>
                <Form.Control
                  as="select"
                  className="shadow"
                  // disabled={currentEvent.id ? true : false}
                  onChange={(spesen) => {
                    handleTagesabschlusschange(spesen.target.value);
                  }}
                >
                  <option
                    className="d-none"
                  //value={currentEvent.uid ? currentEvent.uid : ""}
                  ></option>
                  {spesenoption.map((option) => (
                    <option value={option.key} key={option.key}>
                      {option.Label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="outline-success"
                disabled={false}
                className="shadow"
                onClick={() => {
                  const updates = {};
                  // console.log(utcToZonedTime(day, timeZone).setHours(1,0,0,0))
                  const millesek = utcToZonedTime(day, timeZone).setHours(
                    0,
                    day.getTimezoneOffset()*-1,
                    0,
                    0
                  );

                  updates[
                    "/dailybusiness/" +
                    FID +
                    "/" +
                    millesek +
                    "/anwesenheit/" +
                    User.id
                  ] = newEvent;
                  update(ref(db), updates);
                  //  console.log(updates);
                  setNewEvent(null);
                }}
              >
                Speichern
              </Button>
            </Modal.Footer>
          </div>
        )}
      </Modal>
    </>
  );
};
export default AnwesenheitUser;
