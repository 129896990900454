import React, { useEffect, useState } from "react";
import { Card, ListGroup, Badge, Row, Col } from "react-bootstrap";
import {
  get,
  ref,
  getDatabase,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import { useAuth } from "../../../context/authContext";

export function UrlaubInfo({
  FID,
  year,
  urlaubsDatenYear,
  childToParent,
  isAdmin,
}) {
  const db = getDatabase();
  const [urlaubsdaten, setUrlaubsDaten] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const { currentUser, currentMitarbeiter } = useAuth();

  const response = [];

  useEffect(() => {
    setisLoading(true);
    const fetchData = async () => {
      const queryConstraints = [orderByChild("year"), equalTo(year)];
      const data = await get(
        query(ref(db, "urlaube/" + FID), ...queryConstraints)
      );

      if (data.exists()) {
        var realData = Object.entries(data.val());

        if (!isAdmin) {
          realData = realData.filter((umit) =>
            umit[1].uid.value.includes(currentUser.uid)
          );
        }

        if (realData.length !== 0) {
          realData.forEach(function (childSnapshot) {
            let childdata = childSnapshot[1];

            let childkey = childSnapshot[0];
            const scoreArr = Object.entries(urlaubsDatenYear);

            const filteredArrUrlaube = scoreArr.filter(function ([key, value]) {
              return (
                value["uid"] === childdata["uid"]["value"] &&
                value["year"] === year && value["anlassid"] ==='0'
              );
            });

            const filteredArrKrank = scoreArr.filter(function ([key, value]) {
              return (
                value["uid"] === childdata["uid"]["value"] &&
                value["year"] === year && value["anlassid"] ==='1'
              );
            });

            const KEUrlaube = Object.fromEntries(filteredArrUrlaube);
            const KEKranke = Object.fromEntries(filteredArrKrank);
           

            const Krankheitstage = Object.values(KEKranke).reduce((t, { anzahltage }) => 
              t + anzahltage,
                          0
            );

            //console.log("newScore=>",KEUrlaube)
            const genommender = Object.values(KEUrlaube).reduce((t, { anzahltage }) => 
                  t + anzahltage,
                                0
            );
            const anzahlUrlaube = Object.keys(KEUrlaube).length;

            const GesmatUrlaubJahr =
              childdata["sonderurlaub"] +
              childdata["anspruch"] +
              childdata["rest"];

            const RestUrlaubJahr = GesmatUrlaubJahr - genommender;

            response.push({
              id: childkey,
              year: childdata["year"],
              color: currentMitarbeiter[childdata["uid"]["value"]]["color"],
              uid: childdata["uid"]["value"],
              name: childdata["uid"]["label"],
              rest: childdata["rest"],
              anspruch: childdata["anspruch"],
              sonderurlaub: childdata["sonderurlaub"],
              genommendenUrlaub: genommender,
              anzahlUrlaube: anzahlUrlaube,
              gesamtUrlaubJahr: GesmatUrlaubJahr,
              restUrlaubJahr: RestUrlaubJahr,
              krankheitstage: Krankheitstage
            });
          });
          setUrlaubsDaten(response);
          childToParent(response);
        }else{
          setUrlaubsDaten([]);
        }
      } else {
        setUrlaubsDaten([]);
      }
    };

    if (
      currentMitarbeiter === undefined ||
      currentMitarbeiter === null ||
      FID === null ||
      year === null ||
      urlaubsDatenYear === null
    )
      return;

    fetchData().catch(console.error).finally(setisLoading(false));
  }, [
    FID,   
    isAdmin,
    urlaubsDatenYear,
    year,
  ]);

  return (
    <>
      <React.Fragment>
        <Row>
          {urlaubsdaten.map((userurlaub) => (
            <Col
              key={userurlaub.id}
              className={
                urlaubsdaten.length > 1
                  ? "mt-2 col-md-12 col-lg-12 col-xl-6"
                  : "mt-2 col-md-12 col-lg-12 col-xl-12"
              }
            >
              <Card
                style={{
                  borderColor: userurlaub.color,
                  borderRadius: "30px",
                }}
              >
                <Card.Header
                  style={{
                    fontWeight: "bold",
                    backgroundColor: userurlaub.color,
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                  }}
                >
                  <div className="text-center"> {`${userurlaub.name}`}</div>
                </Card.Header>

                <ListGroup variant="flush">
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <small>Rest von Letzten Jahr</small>
                    <small>
                      <Badge
                        bg="primary"
                        style={{
                          width: "50px",
                        }}
                      >
                        {`${userurlaub.rest}`}
                      </Badge>
                    </small>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <small>Urlaubsanspruch</small>
                    <small>
                      <Badge
                        bg="primary"
                        style={{
                          width: "50px",
                        }}
                      >
                        {`${userurlaub.anspruch}`}
                      </Badge>
                    </small>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <small>Sonderurlaub</small>
                    <small>
                      <Badge
                        bg="primary"
                        style={{
                          width: "50px",
                        }}
                      >
                        {`${userurlaub.sonderurlaub}`}
                      </Badge>
                    </small>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <small>Summe für diese Jahr</small>
                    <small>
                      <Badge
                        bg="primary"
                        style={{
                          width: "50px",
                        }}
                      >
                        {`${userurlaub.gesamtUrlaubJahr}`}
                      </Badge>
                    </small>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <small>Genommende Urlaubstage</small>
                    <small>
                      <Badge
                        bg="warning"
                        style={{
                          width: "50px",
                        }}
                      >
                        {`${userurlaub.genommendenUrlaub}`}
                      </Badge>
                    </small>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <small>Verbleibende Urlaubstage</small>
                    <small>
                      <Badge
                        bg="success"
                        style={{
                          width: "50px",
                        }}
                      >
                        {`${userurlaub.restUrlaubJahr}`}
                      </Badge>
                    </small>
                  </ListGroup.Item>
                  {isAdmin?
                  <>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <small>Krankheitstage</small>
                    <small>
                      <Badge
                        bg="danger"
                        style={{
                          width: "50px",
                        }}
                      >
                        {`${userurlaub.krankheitstage}`}
                      </Badge>
                    </small>
                  </ListGroup.Item>
                  </>:null}

                </ListGroup>

                <Card.Footer
                  className="text-muted "
                  style={{
                    fontWeight: "bold",
                    backgroundColor: userurlaub.uid.color,
                    borderBottomRightRadius: "30px",
                    borderBottomLeftRadius: "30px",
                  }}
                >
                  <small>Gesamt Urlaube: {`${userurlaub.anzahlUrlaube}`}</small>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </React.Fragment>
    </>
  );
}
