import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Table,Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope , faEnvelopeOpen,faTrash} from "@fortawesome/free-solid-svg-icons";
import "./noticebutton.css";
import { ref, getDatabase, onValue,remove ,update} from "firebase/database";
import { useAuth } from "../../../context/authContext";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Cube } from "react-preloaders2";

export const Notice = () => {
  const {  currentUser, currentUserDetail } =  useAuth();  
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [dbNotice, setdbNotice] = useState([]);
  const [FID, setFID] = useState(null);
  const [UID, setUID] = useState(null);
  const db = getDatabase();
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {   
    if(currentUserDetail !==null && currentUserDetail !==undefined){
      setFID(currentUserDetail.fid);    
      setUID(currentUser.uid);
    }
   
  },[currentUser, currentUserDetail]);

  useEffect(() => {
    if (currentUser === null || currentUser === undefined)
        navigate("/login", { replace: true });
        
    if(FID !==null && UID !==null){
        const Noticeref = ref(db, "notice/" + FID + "/" + UID );
        onValue(Noticeref, (snapshot) => {
            const response = [];
            if(snapshot.val() !== null)            
            snapshot.forEach((child) => {  
                let data = child.val();
                let datakey = child.key;              
                response.push({
                    erstellt: new Date(data["erstellt"]),                   
                    gelesen: data["gelesen"],
                    kalenderID: data["kalenderID"],
                    new: data["new"],
                    notice: data["notice"],
                    von: data["von"],
                    id: datakey,                 
                  });
             
            })
            console.log(response)
            setdbNotice(response);
            setisLoading(false)
      });
    }
  },[FID, UID, currentUser, db, navigate]);

  const handlereadNotice = (nachricht) => {
    setError("");
    try {   
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h4>Deine Nachricht vom {nachricht.von}</h4>
              <p>
                {nachricht.notice}
              </p>
              <div className="d-flex justify-content-evenly">
                <Button 
                  variant="outline-primary" 
                  onClick={() => {
                    const updates = {};
                    updates["/notice/" + FID + "/" + UID + "/" + nachricht.id + "/new"  ] = false;
                    update(ref(db), updates);
                    onClose();
                  }}
                  >
                  OK
                </Button>
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    const removes = ref(
                      db,
                      "/notice/" + FID + "/" + UID + "/" + nachricht.id
                    );
                    remove(removes);
                    onClose();
                  }}
                >
                  Kann gelöscht werden!
                </Button>
              </div>
            </div>
          );
        },
      });
    } catch(error){
      console.log(error)
    }
  }

  const handleDeleteNotice = (nachricht) => {
    setError("");
    try {   
      const removes = ref(
        db,
        "/notice/" + FID + "/" + UID + "/" + nachricht.id
      );
      remove(removes);
    } catch(error){
      console.log(error)
    }
  }

  return (
    <>
       <React.Fragment>
        <Container >
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>#</th>
                <th>Herkunft</th>
                <th>Datum / Zeit</th>
                <th>Nachricht</th>
                <th>Löschen</th>
              </tr>
            </thead>
            <tbody>
            {Object.entries(dbNotice).length ===0 ? 
              <tr>
                <td colSpan={5} align="center" style={{ color: "blue"}}>Keine Nachricht vorhanden</td>
              </tr>
            :
            <>
              {dbNotice.map((nachricht,key) => (
                  <tr key={nachricht.id}>
                      <td>{key+1}</td>
                      <td>{nachricht.von}</td>
                      <td>{nachricht.erstellt.toLocaleDateString("de-DE",{year:"numeric",month:"2-digit", day:"2-digit"})}</td>
                      <td>
                          <FontAwesomeIcon onClick= {() => handlereadNotice(nachricht)} icon={nachricht.new? faEnvelope : faEnvelopeOpen} style={{ color: `${nachricht.new? 'green':'red'}` }} />
                      </td>
                      <td>{nachricht.new? 'Zum löschen erst lesen.' : <FontAwesomeIcon onClick= {() => handleDeleteNotice(nachricht)} icon={faTrash} style={{ color: 'red'}}/>} </td>
                  </tr>
                  ))
              }
            </>
            }
            
            
            </tbody>
          </Table>
        </Container>
        <Cube customLoading={!isLoading} time={0} background="#40bfb2" />
        </React.Fragment>
    </>
  );
};
