import React, { useState, useRef } from "react";
import {
  Form,
  Card,
  Button,
  Alert,
  Container,
  Col,
  Navbar,
  InputGroup
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import toast from "react-hot-toast";

export default function PasswortAendern() {
  const cardStyle = {
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: "8px",
    boxShadow: "0 0 15px 1px rgba(0, 0, 0, 0.4)",
  };
  const { resetPassword } = useAuth();
  const emailRef = useRef(null);
  const [loding, setLoding] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      setLoding(true);
      setError("");
      console.log("handleSubmit", e);
      resetPassword(emailRef.current.value)
     
      toast.success(
        "Ihnen wurde grade eine E-Mail gesendet.\nSchauen Sie auch im Spam Ordner nach.",
        {
          duration: 8000,
          position: "top-right",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }
      )
      //  console.log("After Roas");
      

      navigate("/login");
    } catch (error) {
      setError("Fehler beim Einloggen! " + error.message);
    } finally {
      setLoding(false);
    }
  };

  return (
    <>
      <Navbar variant="dark" bg="dark">
        <Container>
          <Navbar.Brand className="mx-auto" style={{ fontSize: 22 }}>
            Service-Subunternehmer
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container fluid>
        <Col xs={12} sm={12} md={6} lg={5} className="mx-auto">
          <Card className="mt-3 bg-light align-items-stretch" style={cardStyle}>
            <Card.Body>
              <h2 className="text-center mb-4">Passwort vergessen?</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group id="email">
                  <Form.Label>E-mail</Form.Label>
                  <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                        @
                      </InputGroup.Text>
                  <Form.Control
                    type="email"
                    required
                    ref={emailRef}
                    placeholder="E-Mail Adresse eingeben"
                    autoComplete="username"
                  ></Form.Control>
                    </InputGroup>
                </Form.Group>

                <Button
                  type="submit"
                  className="w-100 mt-3"
                  variant="outline-success"
                  disabled={loding}
                >
                  E-mail Senden
                </Button>
                <div className="text-center w-100 mt-3">
                  <Link to="/login">Login!</Link>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    </>
  );
}
