import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers,faTruck, faRoad ,faEuroSign,faCalendar,faDownload,faMoneyBill ,faChartLine,faPersonChalkboard, faMap ,faWaveSquare,faChartBar,faCocktail,faPlaneDeparture} from '@fortawesome/free-solid-svg-icons'
import { useAuth } from "../context/authContext";

//const { currentMitarbeiter, currentUser, isAdmin, currentUserDetail } =  useAuth();



export const MenuItems = [
   
    {
      for: "Verwaltung",
      admin: true,
      extern: false,
      title: ' Mitarbeiter',
      path: '/mitarbeiter',
      cName: 'dropdown-link',
      fas: <FontAwesomeIcon icon={faUsers} />
    },
    {
      for: "Verwaltung",
      admin: true,
      extern: false,
      title: ' Fahrzeuge',
      path: '/fahrzeuge',
      cName: 'dropdown-link',
      fas: <FontAwesomeIcon icon={faTruck} />
    },
    {
      for: "Verwaltung",
      admin: true,
      extern: false,
      title: ' Touren',
      path: '/touren',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faRoad} />
    },
    {
      for: "Verwaltung",
      admin: true,
      extern: false,
      title: ' Vergütung',
      path: '/verguetung',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faEuroSign} />
    },
    {
      for: "Verwaltung",
      admin: false,
      extern: false,
      title: ' Kalender',
      path: '/kalender',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faCalendar} />
    },
    {
      for: "Verwaltung",
      admin: false,
      extern: false,
      divider: true   
    },   
   /* {
      for: "Verwaltung",
      admin: false,
      extern: false,
      title: ' Downlaod Live Stopp APK',
      path: '/download',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faDownload} />
    },   */
    {
      for: "Verwaltung",
      admin: false,
      extern: true,
      title: ' Lohnabrechnungen',
      path: "",
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faMoneyBill} />
    },   
    {
      for: "erweiterte Funktionen",
      admin: true,
      extern: false,
      title: ' Stopps',
      path: '/stoppsauswertung',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faChartLine} />
    },   
    {
      for: "erweiterte Funktionen",
      admin: true,
      extern: false,
      title: ' Live',
      path: '/live',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faWaveSquare} />
    },   
    {
      for: "erweiterte Funktionen",
      admin: true,
      extern: false,
      title: ' Live Map',
      path: '/liveMap',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faMap} />
    },   
    {
      for: "erweiterte Funktionen",
      admin: true,
      extern: false,
      title: ' Anwesenheit',
      path: '/anwesenheit',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faPersonChalkboard} />
    },   
    {
      for: "erweiterte Funktionen",
      admin: true,
      extern: false,
      title: ' Diagramme',
      path: '/diagramme',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faChartBar} />
    },
    {
      for: "erweiterte Funktionen",
      admin: false,
      extern: false,
      divider: true   
    },      
    {
      for: "erweiterte Funktionen",
      admin: true,
      extern: false,
      title: ' Hochladen der Lohnabrechnungen',
      path: '/lohnabrechnungen',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faEuroSign} />
    },      
    {
      for: "Verwaltung",
      admin: false,
      extern: false,
      title: ' Spesen Anzeigen',
      path: '/spesenanzeigen',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faCocktail} />
    },      
    {
      for: "erweiterte Funktionen",
      admin: true,
      extern: false,
      title: ' Urlaube Verwalten',
      path: '/urlaube',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faPlaneDeparture} />
    }

  ];






