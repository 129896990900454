import React, { useState, useEffect } from "react";
import { Calendar,DateRangePicker,defaultStaticRanges  } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import { 
  addDays,
  subDays,
  endOfDay,
  startOfDay,
  startOfYear,
  startOfMonth,
  endOfMonth,
  endOfYear,
  addMonths,
  addYears,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays, 
  addWeeks} from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { StoppsAuswahl } from "../stoppsauswertung/stoppsauswahl";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/authContext";
import {
    push,
    child,
    remove,
    update,
    orderByKey,
    startAt,
    endAt,
    query,
      ref,  
      getDatabase,
      onValue,   
      get,
    } from "firebase/database";

import {
  Card,
  Tab,
  Row,
  Col,
  Button,
  ListGroup,
  Badge,
  Stack,
  Container,
} from "react-bootstrap";

import toast from "react-hot-toast";


export const Stopps = () => {

const [ShowStopps, setShowStopps] = useState(false);  


const navigate = useNavigate();
const { currentUser,isAdmin } = useAuth();
const db = getDatabase();
const [FID, setFID] = useState("");
const [locale, setLocale] = useState('de');
const [isLoadingStopps, setLoadingStopps] = useState(false);

const [state, setState] = useState([
  {
    startDate: startOfWeek(new Date(),{weekStartsOn: 1}),
    endDate: endOfWeek(new Date(),{weekStartsOn: 1}),
    key: 'selection'
  }
]);
const [StoppsData, setStoppsData] = useState(null);


useEffect(() => {
    if (currentUser) {
      setFID(currentUser.photoURL);
    }
  }, [currentUser]);

useEffect(() => {
    if (!isAdmin) {
      navigate(-1)
      
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    if (StoppsData != null) {
      setShowStopps(true)
      setLoadingStopps(false);
    }
  }, [StoppsData]);

 

  const handleClick = () => {
    setLoadingStopps(true);   
    setShowStopps(false);
    setStoppsData(null);
    
    const StringStart = String(state[0].startDate.setHours(0,0,0,0))
    const StringEnd = String(state[0].endDate.setHours(23,59,59,0))
  
    const queryConstraints = [orderByKey(), startAt(StringStart),endAt(StringEnd)];   
    get(query(ref(db, 'dailybusiness/' + FID), ...queryConstraints)).then(
      (data) => {       
        if (data.exists()) {
          const stopps = []
          Object.entries(data.val()).map(([key,value])=>{
            if(Object.keys(value).some(key => key === "stopps")){
              stopps.push({key:key, stopps:value["stopps"]})
            }           
          });
          if(stopps.length > 0 ){
           // console.log("stopps =>",stopps)
            setStoppsData(stopps)
          }else{
            console.log("stopps =>",stopps)
            toast.error('Keine Stopp Daten vorhanden');
            setLoadingStopps(false);
          }
        }else{
          toast.error('Keine Stopp Daten vorhanden');
          setLoadingStopps(false);
        }      
      }
    )  
  };
  
  
  const childToParent = (e, data) => {
   
  };
  

    return(
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={3}>
            <Card className="mt-3 bg-light align-items-stretch myCard">
              <Card.Header className="text-center">
                <div>Auswahl des Zeitraumes der Stopps zum bearbeiten</div>
              </Card.Header>
              <Card.Body className="d-flex flex-column">
                <DateRangePicker className="mt-3"
                  onChange={item => setState([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  locale={locales[locale]}
                  ranges={state}
                  direction="horizontal"
                  shouldCloseOnSelect={false}
                  inputRanges={[]}
                  staticRanges={[          
                    {
                      label: "Diese Woche",
                      range: () => ({
                        startDate: startOfWeek(new Date(),{weekStartsOn: 1}),
                        endDate: endOfWeek(new Date(),{weekStartsOn: 1})
                      }),
                      isSelected(range) {
                        const definedRange = this.range();
                        return (
                          isSameDay(range.startDate, definedRange.startDate) &&
                          isSameDay(range.endDate, definedRange.endDate)
                        );
                      }
                    },
                    {
                      label: "Lezte Woche",
                      range: () => ({
                        startDate: startOfWeek(addWeeks(new Date(), -1),{weekStartsOn: 1}),
                        endDate: endOfWeek(addWeeks(new Date(), -1),{weekStartsOn: 1})
                      }),
                      isSelected(range) {
                        const definedRange = this.range();
                        return (
                          isSameDay(range.startDate, definedRange.startDate) &&
                          isSameDay(range.endDate, definedRange.endDate)
                        );
                      }
                    },
                    {
                      label: "Diesen Monat",
                      range: () => ({
                        startDate: startOfMonth(new Date()),
                        endDate: endOfMonth(new Date())
                      }),
                      isSelected(range) {
                        const definedRange = this.range();
                        return (
                          isSameDay(range.startDate, definedRange.startDate) &&
                          isSameDay(range.endDate, definedRange.endDate)
                        );
                      }
                    },
                    {
                      label: "Letztes Monat",
                      range: () => ({
                        startDate: startOfMonth(addMonths(new Date(), -1)),
                        endDate: endOfMonth(addMonths(new Date(), -1))
                      }),
                      isSelected(range) {
                        const definedRange = this.range();
                        return (
                          isSameDay(range.startDate, definedRange.startDate) &&
                          isSameDay(range.endDate, definedRange.endDate)
                        );
                      }
                    },
                    {
                      label: "Dieses Jahr",
                      range: () => ({
                        startDate: startOfYear(new Date()),
                        endDate: endOfDay(new Date())
                      }),
                      isSelected(range) {
                        const definedRange = this.range();
                        return (
                          isSameDay(range.startDate, definedRange.startDate) &&
                          isSameDay(range.endDate, definedRange.endDate)
                        );
                      }
                    },
                    {
                      label: "Letztes Jahr",
                      range: () => ({
                        startDate: startOfYear(addYears(new Date(), -1)),
                        endDate: endOfYear(addYears(new Date(), -1))
                      }),
                      isSelected(range) {
                        const definedRange = this.range();
                        return (
                          isSameDay(range.startDate, definedRange.startDate) &&
                          isSameDay(range.endDate, definedRange.endDate)
                        );
                      }
                    }
                    
                  ]}
                />                    
              </Card.Body>
              <Card.Footer>
                    <div className="d-grid gap-2 mt-auto">
                      <Button 
                        variant="outline-success" 
                        disabled={isLoadingStopps}
                        size="lg"
                        onClick={!isLoadingStopps ? handleClick : null}
                      >{isLoadingStopps ? 'Lade…Stopps' : 'Stopps abholen'}
                      </Button>    
                    </div>
                  </Card.Footer>
            </Card>
          </Col>
          {ShowStopps ? (
          <Col xs={12} sm={12} md={12} lg={12} xl={9} >
            <StoppsAuswahl
                childToParent={childToParent}
                setShowStoppAuswahl={setShowStopps}
                stoppauswahl={StoppsData}                
                  >
            </StoppsAuswahl>
          </Col> 
          ) : (
           <></>
          )}
        </Row>
        
      </Container>
      
    );
}