import React from "react";
import { useLocation,useNavigate } from "react-router-dom";
import { Button, Container, Navbar } from "react-bootstrap";
import { useAuth } from "../../../context/authContext";

export const NoMatch = () => {
  const  location  = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const routeChange =() =>{
    navigate("/login");
  }

  return (
   <>
      {currentUser ? (
       
    
      <h1 className='download'>404 diese Seite gibt es nicht</h1>
   
  
      ):( 
          <>
          <Navbar variant="dark" bg="dark">
          <Container>
            <Navbar.Brand className="mx-auto" style={{ fontSize: 22 }}>
              Service-Subunternehmer
            </Navbar.Brand>
          </Container>
        </Navbar>
        
      <div className='download'>
        <h3 >
          404 diese Seite gibt es nicht<br/>
          Konnte die Seite {location.pathname} nicht finden.<br/><br/><br/>
          <div className="text-center">
          <Button  onClick={routeChange} > Hier geht es zum Login</Button>
        </div>
        </h3>
        
        
      </div>
      
      </>
      )}
      </>
    
  );
};
