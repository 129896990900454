import React, { useState,useEffect } from "react";
import { Card, Container } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../context/authContext";
import { 
  orderByKey,
  startAt,
  endAt,
  query,
  ref, 
  get,
  getDatabase
} from "firebase/database";
import {  
  startOfYear,
  endOfYear
  
} from "date-fns";

function Dashbord() {
  const navigate = useNavigate();
  const { currentUser,currentFirma,currentUserDetail,currentMitarbeiter,isAdmin} = useAuth();
  const [isLoading, setisLoading] = useState(true);
  const [FID, setFID] = useState("");
  const db = getDatabase();
  const [SumStopps, setSumStopps] = useState(0);
  const [SumDays, setSumDays] = useState(0);
  const [SumMeter, setSumMeter] = useState(0);
  const [avgStopps, setavgStopps] = useState(0);
  const [avgkm, setavgkm] = useState(0);
  useEffect(() => {    
    setisLoading(true); 

    const getData = (start, ende) => {   
      const queryConstraints = [orderByKey(), startAt(start), endAt(ende)];
      get(query(ref(db, "dailybusiness/" + FID), ...queryConstraints)).then(
        (data) => {
          if (data.exists()) {
         
            if (isAdmin) {
            
            } else {
              var stoppCount =0
              var dayCount =0
              var distanceCount =0
              Object.entries(data.val()).forEach(([key, value]) => {
                    dayCount += 1
                    const filteredStopps =  Object.values(value.stopps).filter(stopp => stopp.userid === currentUserDetail.id);
                    distanceCount += filteredStopps.reduce((acc, stopp) => acc + stopp.distance, 0);                                    
                    stoppCount += filteredStopps.length;                    
                  //  console.log(filteredStopps)
              })
              setSumStopps(stoppCount)
              setSumDays(dayCount)
              setSumMeter(distanceCount)
              setavgStopps((stoppCount / dayCount).toFixed(2))
              setavgkm(((distanceCount/1000) / dayCount).toFixed(0))

          //    console.log(((distanceCount/1000) / dayCount).toFixed(0))
          //    console.log((stoppCount / dayCount).toFixed(2))

            

              


            }
          }
        }
      );
    };
    
    if (!currentUser) 
      navigate("/login", { replace: true });
    if (
      currentFirma !== undefined && currentFirma !== null &&
      currentUserDetail !== undefined && currentUserDetail !== null &&
      currentMitarbeiter !== undefined && currentMitarbeiter !== null
      ){
        setFID(currentUser.photoURL);
        getData(
          String(startOfYear(new Date()).setHours(0, 0, 0, 0)),
          String(endOfYear(new Date()).setHours(23, 59, 59, 0))
        )


       setisLoading(false);
    } 

    
       
  },[currentFirma, currentUserDetail, currentMitarbeiter, currentUser, navigate, db, FID, isAdmin]);

  

  return (
    
    <>
      {!isLoading && currentUser?
        <Container  className="mt-3">
      <Card>
        <Card.Header>
           <h1 className="text-center">Dashbord</h1>
        </Card.Header>
        <Card.Body>        
         <div> Du machst durchschnittlich: <b className="text-primary">{avgStopps}</b> Stopps</div>
         <div> Du fährst im durchschnitt: <b className="text-primary">{avgkm} km </b>am Tag</div>
         <div> Du hast bis heute <b className="text-primary">{SumDays}</b> Tage gearbeitet</div>
         <div> Insgesamt bist du <b className="text-primary">{(SumMeter / 1000).toFixed(0)}</b> Kilometer gefahren </div>
         <div> Insgesamt hast du <b className="text-primary">{SumStopps}</b> Stopps gemacht.</div>
        </Card.Body>
      </Card>
      </Container>
    :<></>}
    </>
  );
 
}
export default Dashbord;