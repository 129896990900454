import React, { useState, useEffect ,useRef} from "react";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/authContext";
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
  Container, 
} from "react-bootstrap";
import "./StyleKalender.css";
import Calendar from "rc-year-calendar";
import "rc-year-calendar/locales/rc-year-calendar.de";

import Moment, * as momentHolly from "moment-feiertage";
import { extendMoment } from "moment-range";

import {
  ref,
  getDatabase,
  child,
  orderByChild,
  get,
  update,
  push,
  query,
  remove,
  equalTo,
  onValue,
} from "firebase/database";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfYear,
  startOfMonth,
  endOfMonth,
  format ,
  endOfYear,
  addMonths,
  addYears,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
  addWeeks,
} from "date-fns";
import tippy, { animateFill } from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "tippy.js/dist/backdrop.css";
import "tippy.js/animations/shift-away.css";
//import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { UrlaubInfo } from "./UrlaubInfo";
import { confirmAlert } from "react-confirm-alert"; // Import
import { faIdBadge, faTruckFieldUn } from "@fortawesome/free-solid-svg-icons";
//import Select from 'react-select'
import toast from "react-hot-toast";
import { renderToString } from "react-dom/server";
import { Cube } from "react-preloaders2";

export default function Kalender() {
  const navigate = useNavigate();
  const { currentMitarbeiter, currentUser, isAdmin, currentUserDetail } =
    useAuth();
  const [FID, setFID] = useState(null);
  const [currentDate] = useState(new Date());
  const [showWeekends, setshowWeekends] = useState(false);
  const [showWeeknumbers, setshowWeeknumbers] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [minDate, setDateminDate] = useState(null);
  const [currentYear, SetcurrentYear] = useState(null);
  const [maxDate, setmaxDate] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [kalenderEvent, setkalenderEvent] = useState(null);  
  const [Urlaubssperre, setUrlaubssperre] = useState(false)
  const UrlaubssperreRef = useRef(null);

  const [DisabledDays, setDisabledDays] = useState([]);
  const [weekendarray, setweekendarray] = useState([6, 0]);
  const [mydata, setmydata] = useState(null);
  const [uid, setUID] = useState(null);
  const [updateUrlaubsTage, setupdateUrlaubsTage] = useState(0);
  const [ModalPrimaryButtonDisabled, setModalPrimaryButtonDisabled] =
    useState(false);
  const [contextmenu, setcontextnemu] = useState([
    { text: "Bearbeiten", key: "0", click: (evt) => UpdateEvent(evt) },
    {
      text: "Löschen",
      key: "1",
      click: (evt) => deleteCurrentEvent(evt),
    },
    { text: "Genehmigen", key: "2", click: (evt) => GenehmigenEvent(evt) },
    {
      text: "Genehmigen lassen",
      key: "3",
      click: (evt) => GenehmigungAnfrageEvent(evt),
    },
  ]);

  const [optionsAnlass, setoptionsAnlass] = useState([
    { label: "Urlaub", value: 0, key: "urlaub" },
  ]);
  const [optionsMitarbeiter, setoptionsMitarbeiter] = useState([]);
  const [UserUrlaubsDaten, setUserUrlaubsDaten] = useState(null);
  const [EventUserSelect, setEventUserSelect] = useState(null);
  const AnzalTageRef = React.createRef();
  const enddayref = React.createRef();
  const startdayref = React.createRef();

  const [anzahlTage, setAnzahlTage] = useState(0);
  const [endDate, setendDate] = useState(0);
  const [startDate, setstartDate] = useState(0);

  const [KalMinDate, setKalMinDate] = useState(momentHolly(startOfYear(new Date())).format("YYYY-MM-DD"));
  const [KalMaxDate, setKalMaxDate] = useState(momentHolly(endOfYear(new Date())).format("YYYY-MM-DD"));
  const [Kalcolor, setKalKalcolor] = useState('#ff0000');
  const KalMinDateref = React.createRef();
  const KalMaxDateref = React.createRef();
  const Kalcoloreref = React.createRef();
  const [kaleinstellung, setkaleinstellung] = useState(false);


  const exmoment = extendMoment(Moment);
  const dbRef = ref(getDatabase());
  const db = getDatabase();

  const customStyles = [{ label: "custom" }];

  function workingDaysBetweenDates(d0, d1) {
    var mydays = 0;

    while (d0 <= d1) {
      if (
        !momentHolly(d0).isHoliday("HH") &&
        d0.getDay() > 0 &&
        d0.getDay() < 6
      ) {
        mydays++;
      }

      var newDate = d0.setDate(d0.getDate() + 1);
      d0 = new Date(newDate);
    }
    return mydays;
  }

  useEffect(() => {
    if (isAdmin) {
      const OM = {
        label: "Krank",
        value: 1,
        key: "krank",
      };
      setoptionsAnlass((optionsAnlass) => [...optionsAnlass, OM]);
    }
  }, [isAdmin]);

  useEffect(() => {
    if (currentUser === null || currentUser === undefined)
      navigate("/login", { replace: true });

    if (
      currentUser &&
      currentMitarbeiter !== undefined &&
      currentMitarbeiter !== null &&
      Object.keys(currentMitarbeiter).length !== 0
    ) {
      setFID(currentUser.photoURL);
      setUID(currentUser.uid);

      const kaleref = ref(db, "kalender/" + currentUser.photoURL);
      onValue(kaleref, (snapshot) => {
        if (snapshot.exists()) {
          const response = [];
          snapshot.forEach((child) => {
            let data_1 = child.val();
            let dataKey = child.key;

            /*   console.log("dataKey=>",dataKey)*/
            //   console.table("data_1=>",data_1)
            //  console.table("currentMitarbeiter uid=>",currentUser.uid)

            let tmpcolore = "#000000";
            if (currentUser.uid === data_1["uid"] || isAdmin) {
              //  console.log("Color=> ",currentMitarbeiter[data_1 ["uid"]])
              //  if(typeof currentMitarbeiter[data_1["uid"]] != 'undefined'){

              tmpcolore = data_1["color"];
              // tmpcolore = currentMitarbeiter[currentUser.uid]["color"]
              // }
              //
            }

            response.push({
              name: data_1["name"],
              anlass: data_1["anlass"],
              startDate: new Date(data_1["startDate"]),
              endDate: new Date(data_1["endDate"]),
              anlassid: data_1["anlassid"],
              anzahltage: data_1["anzahltage"],
              approvedDate: data_1["approvedDate"],
              color: tmpcolore,
              id: dataKey,
              kommentar: data_1["kommentar"],
              status: data_1["status"],
              submittedDate: new Date(data_1["submittedDate"]),
              updatedDate: data_1["updatedDate"],
              uid: data_1["uid"],
              fid: data_1["fid"],
              year: data_1["year"],
            });
          });
          setmydata(response);
        }
      });
    }
  }, [currentMitarbeiter, currentUser, db, dbRef, isAdmin, navigate]);

  useEffect(() => {
    if (currentMitarbeiter === undefined || currentMitarbeiter === null) return;
    if (Object.keys(currentMitarbeiter).length === 0 || !UserUrlaubsDaten)
      return;
    KalenderEinstellungen()
    setoptionsMitarbeiter([]);
    Object.entries(currentMitarbeiter).forEach((element) => {
      if (UserUrlaubsDaten.find((x) => x["uid"] === element[0])) {
        const OM = {
          value: element[0],
          label: element[1]["name"],
          color: element[1]["color"],
        };

        setoptionsMitarbeiter((optionsMitarbeiter) => [
          ...optionsMitarbeiter,
          OM,
        ]);
      }
    });
  }, [UserUrlaubsDaten, currentMitarbeiter]);

  useEffect(() => {
    if (AnzalTageRef === undefined || AnzalTageRef === null) return;
    if (AnzalTageRef.current) {
      AnzalTageRef.current.value = anzahlTage;
    }
  }, [AnzalTageRef, anzahlTage]);

  useEffect(() => {
    if (enddayref === undefined || enddayref === null) return;
    if (enddayref.current) {
      enddayref.current.value = endDate;
    }
  }, [enddayref, endDate]);

  useEffect(() => {
    if (startdayref === undefined || startdayref === null) return;
    if (startdayref.current) {
      startdayref.current.value = startDate;
    }
  }, [startdayref, startDate]);

  useEffect(() => {
    if (KalMinDateref === undefined || KalMinDateref === null) return;
    if (KalMinDateref.current) {
      KalMinDateref.current.value = KalMinDate;
    }
  }, [KalMinDate, KalMinDateref]);
  useEffect(() => {
    if (KalMaxDateref === undefined || KalMaxDateref === null) return;
    if (KalMaxDateref.current) {
      KalMaxDateref.current.value = KalMaxDate;
    }
  }, [KalMaxDate, KalMaxDateref]);

  useEffect(() => {
    if (Kalcoloreref === undefined || Kalcoloreref === null) return;
    if (Kalcoloreref.current) {
      Kalcoloreref.current.value = Kalcolor;
    }
  }, [Kalcolor, Kalcoloreref]);
  //KalMinDateref.current.value = new Date()
  //        KalMaxDateref.current.value = new Date()
  //        Kalcoloreref.current.value = '#ff0000'

  useEffect(() => {
    console.log("TEST ",Urlaubssperre)
    if (UrlaubssperreRef.current) {
      UrlaubssperreRef.current = Urlaubssperre;
    
    }
   
  }, [Urlaubssperre]);

  

  useEffect(() => {
    // setisLoading(true);

    if (
      currentMitarbeiter === undefined ||
      currentMitarbeiter === null ||
      FID === null ||
      currentYear === null ||
      mydata === null ||
      uid === null 
     
    ){
       return;
    }else{
      setisLoading(false);
    }
         
  }, [FID, currentMitarbeiter, currentYear,  mydata, uid]);

  // useEffect(() => {

  //}, [ mydata]);

  const handleononYearChanged = (e) => {
    SetcurrentYear(e.currentYear);
    setoptionsMitarbeiter([]);
  };

  const handleonRangeSelected = (SSDate, ENDate) => {

    const sDate = new Date(SSDate);
    const eDate = new Date(ENDate);
    sDate.setHours(0, 0, 0);
    eDate.setHours(0, 0, 0);

    console.log(UrlaubssperreRef.current)
   // if(!Urlaubssperre){
   

      if (Moment(eDate).diff(Moment(sDate)) < 0) {
        setModalPrimaryButtonDisabled(true);
        setAnzahlTage("Bis Datum zu klein");
        return;
      }

      if (Moment(sDate).diff(Moment(eDate)) > 0) {
        setModalPrimaryButtonDisabled(true);
        setAnzahlTage("Von Datum zu Groß");
        return;
      }

      const BDays = workingDaysBetweenDates(sDate, eDate);
    

      if (BDays > 0) {
        sDate.setDate(sDate.getDate() - 1);
        setAnzahlTage(BDays);
        setendDate(momentHolly(eDate).format("YYYY-MM-DD"));
        setstartDate(momentHolly(sDate).format("YYYY-MM-DD"));
        setCurrentEvent({
          ...currentEvent,
          startDate: sDate,
          endDate: eDate,
          anzahltage: BDays,
        });
      }
    
  };

  const handlecustomDayRenderer = (element, date) => {
    try {
      currentDate.setHours(0, 0, 0, 0);
      if (date.getTime() === currentDate.getTime()) {
        element.style.background = "green";
        element.style.color = "white";
        element.style.borderRadius = "50%";
      }

      const isHolidayInHamburg = momentHolly(date).isHoliday("HH");
      if (isHolidayInHamburg) {
        element.style.background = "lightblue";
        element.style.color = "black";
        element.style.borderRadius = "25%";
      }
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.table(errorMessage);
    } finally {
      // console.table(fireEvent);
    }
  };

  const handlecustomcustomDataRenderer = (element, date, events) => {
    let c = element.closest("td");
    var content = "";
    let zz = 0;
    let zz1 = 0;
    content += events.map((UEvent) => {
      if (isAdmin) {
        if (UEvent.anlassid === "1") {
          zz -= 4;
          c.style.color = "red";
          return UEvent.color + " " + zz + "px 0px 0px 0px inset";
        }

        //  c.style = "background: red; color: white; border-radius: 50%; width: 4px; height: 4px;";
        // c.style= "display: inline-flex;background: red;/* margin-left: 12px; */margin-right: 16px;margin-top: -20px;border-radius: 50%;border-style: solid;border-width: 1px;border-color: red;height: 8px;width: 8px;a;ustify-content: center;"
      }
      //console.log(UEvent)
      zz1 -= 4;
      return UEvent.color + " 0px " + zz1 + "px 0px 0px inset";
    });

    // c.style.boxShadow = 'rgb(255, 0, 0) 0px -16px 0px 0px inset, rgb(0, 255, 0) 0px -32px 0px 0px inset'
    c.style.boxShadow = content;

    //  console.log("handlecustomcustomDataRenderer content=>",content)
    //  console.log("handlecustomcustomDataRenderer=>",date)
    //  console.log("handlecustomcustomDataRenderer=>",events)
  };

  const handleDayEnter = (e) => {
    const isHolidayInHamburg = momentHolly(e.date).isHoliday("HH");

    if (e.tooltip != null) {
      e.tooltip.destroy();
      e.tooltip = null;
    }
    var content = "";
    if (isHolidayInHamburg) {
      content +=
        "<div class='text-center' style='color:yellowgreen;'>" +
        isHolidayInHamburg +
        "</div>";
      e.tooltip = tippy(e.element, {
        plugins: [animateFill],
        placement: "right",
        allowHTML: true,
        content: content,
        animateFill: false,
        animation: "shift-away",
        arrow: true,
      });
      e.tooltip.show();
      return;
    }

    var tableStyle = {
      border: "1px solid white ",
    };

    content += e.events.map((UEvent) =>
      renderToString(
        <>
          {UEvent.uid === currentUser.uid || isAdmin ? (
            <table style={tableStyle} width="100%">
              <tbody>
                <thead>
                  <tr>
                    <th colSpan={3} style={{ color: UEvent.color }}>
                      {UEvent.name}
                    </th>

                    <th className="right" width="60%">
                      {UEvent.anzahltage} Tage {UEvent.anlass}
                    </th>
                  </tr>
                </thead>

                <tr>
                  <td
                    rowSpan={isAdmin && UEvent.anlassid === "1" ? 1 : 2}
                    colSpan={2}
                  >
                    {isAdmin && UEvent.anlassid === "1"
                      ? "Krankmeldung"
                      : "Eingereicht:"}
                  </td>
                  <td align="center">am: </td>
                  <td align="right">
                    {momentHolly(UEvent.submittedDate).format("DD.MM.YYYY")}
                  </td>
                </tr>
                {isAdmin && UEvent.anlassid === "1" ? (
                  <></>
                ) : (
                  <tr>
                    <td align="center">um: </td>
                    <td align="right">
                      {momentHolly(UEvent.submittedDate).format("HH:mm:ss")}
                    </td>
                  </tr>
                )}
                {UEvent.updatedDate !== 0 ? (
                  <>
                    <tr>
                      <td rowSpan={2} colSpan={2}>
                        Letzte Änderung:
                      </td>
                      <td align="center">am:</td>
                      <td align="right">
                        {momentHolly(UEvent.updatedDate).format("DD.MM.YYYY")}
                      </td>
                    </tr>
                    <tr>
                      <td align="center">um:</td>
                      <td align="right">
                        {momentHolly(UEvent.updatedDate).format("HH:mm:ss")}
                      </td>
                    </tr>
                  </>
                ) : null}

                {UEvent.anlassid === "1" ? (
                  <></>
                ) : (
                  <>
                    {UEvent.approvedDate === 0 ? (
                      <tr>
                        <td colSpan={4} align="center">
                          {UEvent.status === 0 ? (
                            <b style={{ color: "#EC7063" }}>
                              Bitte genehmigen lassen!
                            </b>
                          ) : (
                            <b style={{ color: "orange" }}>
                              Bei der Überprügung.
                            </b>
                          )}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          colSpan={4}
                          align="center"
                          style={{ color: "yellowgreen" }}
                        >
                          <b>
                            Genenmigt am:{" "}
                            {momentHolly(UEvent.approvedDate).format(
                              "DD.MM.YYYY"
                            )}
                          </b>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          ) : null}
        </>
      )
    );
    content = content.replace(/,/g, "");
    if (content.length !== 0) {
      e.tooltip = tippy(e.element, {
        plugins: [animateFill],
        placement: "right",
        allowHTML: true,
        content: content,
        animateFill: false,
        animation: "shift-away",
        arrow: true,
      });
    }

    if (typeof e.tooltip !== "undefined" && e.tooltip != null) {
      e.tooltip.show();
    }
  };

  const handleonDayContextMenu = (e) => {
    if (!isAdmin) {
      [...document.getElementsByClassName("submenu")].forEach(
        (element, index, array) => {
          element.removeChild(element.children[2]);

          if (
            e.events[index].anlassid === "1" ||
            currentUser.uid !== e.events[index].uid
          ) {
         //   console.log("TEST=>", currentUser.uid);
            element.parentElement.remove();
          }

         // console.log("parentElement=>", e.events[index].status);
          if (e.events[index].approvedDate !== 0) {
            element.parentElement.remove();
          }
          if (e.events[index].status !== 0) {
            element.removeChild(element.children[2]);
          }
        }
      );

      /* Array.from(
        document.getElementsByClassName("calendar-context-menu")[0].children
      ).forEach(function (item) {
        const name = item.innerText.substring(0, item.innerText.length - 2);
        if (name !== currentUserDetail.name) {
          item.remove();
        } else {
        }
      });
      try {
         const eChilds = Array.from(
            document.getElementsByClassName("submenu")[0].children
          );
        for (var i in e.events) {             
          if (e.events[i].approvedDate !== 0 && currentUserDetail.id === e.events[i].uid ) {
            eChilds[0].remove();
            eChilds[1].remove();
            eChilds[2].remove();
            eChilds[3].remove();
          } else if(e.events[i].status !== 0 && currentUserDetail.id === e.events[i].uid) {
            eChilds[0].remove();
            eChilds[1].remove();
            eChilds[2].remove();
            eChilds[3].remove();
          } else if(e.events[i].status === 0 && currentUserDetail.id === e.events[i].uid) {
            eChilds[2].remove();
          }
        }
      } catch {}*/
    } else {
      // const eChilds = Array.from(
      //  document.getElementsByClassName("submenu")[0].children
      // );

      [...document.getElementsByClassName("submenu")].forEach(
        (element, index, array) => {
          if (
            e.events[index].approvedDate !== 0 ||
            e.events[index].anlassid === "1"
          ) {
            element.removeChild(element.children[3]);
            element.removeChild(element.children[2]);
          } else if (e.events[index].status !== 0) {
            element.removeChild(element.children[3]);
          }
        }
      );
    }
  };

  const deletesubmit = (evt) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Bist du dir Sicher?</h1>
            <p>
              Das du den {evt.anlass} von {evt.name} von{" "}
              {momentHolly(evt.startDate).format("DD.MM.YYYY")} bis{" "}
              {momentHolly(evt.endDate).format("DD.MM.YYYY")} löschen möchtest.
            </p>
            <div className="d-flex justify-content-evenly">
              <Button variant="outline-primary" onClick={onClose}>
                Abbrechen
              </Button>
              <Button
                variant="outline-danger"
                onClick={() => {
                  const removes = ref(
                    db,
                    "/kalender/" + evt.fid + "/" + evt.id
                  );
                  remove(removes);
                  onClose();
                }}
              >
                Ja, lösch es!
              </Button>
            </div>
          </div>
        );
      },
    });
  };

  const deleteCurrentEvent = (evt) => {
    deletesubmit(evt);
  };

  const CheckofMySelfHollyday = (UID, CE, start, end) => {
    const queryConstraints = [orderByChild("uid"), equalTo(UID)];
    try {
      return new Promise((resolve) => {
      //  console.log(CE.id);
        if (CE.id !== undefined) resolve(false);
        get(query(ref(db, "kalender/" + FID), ...queryConstraints)).then(
          (data) => {
            if (data.exists()) {
              var realData = Object.entries(data.val());

              realData = realData.filter(
                (umit) => umit[1].year === currentYear
              );

              if (realData.length !== 0) {
                const range1 = exmoment.range(start, end);

                realData.forEach(function (childSnapshot) {
                  let childdata = childSnapshot[1];
                  const range2 = exmoment.range(
                    childdata["startDate"],
                    childdata["endDate"]
                  );
                  if (range1.overlaps(range2, { adjacent: true }) === true) {
                    resolve(true);
                  }
                });
              }
              resolve(false);
            } else {
              resolve(false);
            }
          }
        );
      });
    } catch (error) {
      console.log(error);
    }
  };

  const saveCurrentEvent = () => {
    if (typeof currentEvent.uid === "undefined") {
      toast.error(
        "Es ist kein Mitarbeiter ausgewählt. Bitte einen Mitarbeiter Auswählen"
      );
      return;
    }

    if (typeof currentEvent.anlass === "undefined") {
      toast.error("Bitte einen Anlass auswählen.");
      return;
    }

    if (
      currentEvent.anlassid === "0" &&
      !checkgenugUrlaub(currentEvent.uid, currentEvent.anzahltage)
    ) {
      //setEventUserSelect(null);
      //setCurrentEvent({ ...currentEvent, uid: null });
      return;
    }

    setModalPrimaryButtonDisabled(true);
    const uuData = optionsMitarbeiter.find(
      (x) => x["value"] === currentEvent.uid
    );
  //  console.log("uuData=>", uuData);

    CheckofMySelfHollyday(
      uuData.value,
      currentEvent,
      currentEvent.startDate.getTime(),
      currentEvent.endDate.getTime()
    ).then((resual) => {
     // console.log("resual=>", resual);
      if (resual === true) {
        toast.error(
          "Der Zeitraum überlappt sich mit einen anderen Urlaub von die."
        );
        return;
      } else {
        var tmpID = currentEvent.id;
        if (!currentEvent.id) {
          tmpID = push(child(ref(db), "kalender")).key;
        }

        const postData = {
          name: uuData.label,
          anlass: currentEvent.anlass,
          startDate: currentEvent.startDate.getTime(),
          endDate: currentEvent.endDate.getTime(),
          anlassid: currentEvent.anlassid,
          anzahltage: currentEvent.anzahltage,
          approvedDate: 0,
          color: uuData.color,
          kommentar: "",
          status: currentEvent.id !== undefined ? currentEvent.status : 0,
          submittedDate:
            currentEvent.id !== undefined
              ? currentEvent.submittedDate.getTime()
              : new Date().getTime(),
          updatedDate: currentEvent.id !== undefined ? new Date().getTime() : 0,
          uid: uuData.value,
          fid: FID,
          id: tmpID,
          year: currentYear,
        };

        const updates = {};

        updates["/kalender/" + FID + "/" + postData.id] = postData;
        update(ref(db), updates);
        setCurrentEvent(null);
        setupdateUrlaubsTage(0);
      }
    });
  };

  const useronChange = (e) => {
    // if (!checkgenugUrlaub(e, currentEvent.anzahltage)) {
    //   setEventUserSelect(null);
    //   setCurrentEvent({ ...currentEvent, uid: null });
    //   return;
    // }
    setModalPrimaryButtonDisabled(false);
    setEventUserSelect(e);
    setCurrentEvent({ ...currentEvent, uid: e });
  };

  const anlassonChange = (e) => {
    //console.log("anlassonChange:=>", optionsAnlass.find(option => option.value === parseInt(e)).label)

    // if (  e=== '0' && !checkgenugUrlaub(e, currentEvent.anzahltage)) {
    //   setEventUserSelect(null);
    //   setCurrentEvent({ ...currentEvent, uid: null });
    //   return;
    // }
    setModalPrimaryButtonDisabled(false);
    setCurrentEvent({
      ...currentEvent,
      anlassid: e,
      anlass: optionsAnlass.find((option) => option.value === parseInt(e))
        .label,
    });
  };

  const UpdateEvent = (evt) => {
    setEventUserSelect(evt.uid);
    setupdateUrlaubsTage(evt.anzahltage);
    setCurrentEvent(evt);
  };

  const GenehmigenEvent = (evt) => {
    const updates = {};

    updates["/kalender/" + evt.fid + "/" + evt.id + "/approvedDate"] =
      new Date().getTime();
    updates["/kalender/" + evt.fid + "/" + evt.id + "/status"] = 2;

    const noticeID = push(child(ref(db), "kalender")).key;

    const noticeData = {
      erstellt: new Date().getTime(),
      gelesen: 0,
      new: true,
      von: "Kalender",
      notice:
        "Der Urlaubsantrag von " +
        evt.submittedDate.toLocaleDateString("de-DE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }) +
        " wurde dir genehmigt. Dein Urlaub beginnt am " +
        evt.startDate.toLocaleDateString("de-DE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }) +
        " und endet am: " +
        evt.endDate.toLocaleDateString("de-DE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
      kalenderID: evt.id,
    };

    updates["/notice/" + evt.fid + "/" + evt.uid + "/" + noticeID] = noticeData;

    update(ref(db), updates);
  };

  const GenehmigungAnfrageEvent = (evt) => {
    const updates = {};
    updates["/kalender/" + evt.fid + "/" + evt.id + "/status"] = 1;

    const noticeID = push(child(ref(db), "kalender")).key;
   // console.log("TEST=>", evt);
    const noticeData = {
      erstellt: new Date().getTime(),
      gelesen: 0,
      new: true,
      von: "Kalender",
      notice:
        evt.name +
        " hat ein Urlaubsantrag über " +
        evt.anzahltage +
        " Tag(e) gestellt.",
      kalenderID: evt.id,
    };

    updates["/notice/" + evt.fid + "/" + evt.fid + "/" + noticeID] = noticeData;

    update(ref(db), updates);
  };

  const checkgenugUrlaub = (e, UTage) => {
    const UDM = UserUrlaubsDaten.find((x) => x["uid"] === currentEvent.uid);

    const verbleibend = UDM.restUrlaubJahr + updateUrlaubsTage;

    if (UTage > verbleibend) {
      setModalPrimaryButtonDisabled(true);
      toast.error(`${UDM.name} hat nicht mehr soviel Urlaub.`);

      return false;
    }
    setModalPrimaryButtonDisabled(false);
    return true;
  };

  const ModalEntered = () => {
    if (currentEvent) {
      enddayref.current.value = momentHolly(currentEvent.endDate).format(
        "YYYY-MM-DD"
      );
      startdayref.current.value = momentHolly(currentEvent.startDate).format(
        "YYYY-MM-DD"
      );
      AnzalTageRef.current.value = currentEvent.anzahltage;
    }
  };

  const handleChange = () => {
    if (showWeekends) {
      setweekendarray([6, 0]);
    } else {
      setweekendarray([]);
    }
    setshowWeekends(!showWeekends);
  };

  const handleChangeWeekNumber = () => {
    setshowWeeknumbers(!showWeeknumbers);
  };

  const childToParent = (udaten) => {
    setUserUrlaubsDaten(udaten);
  };


  const KalenderEinstellungen = () => {

   // console.log(kalenderEvent)
      const kaleinstellungref = ref(db, "kalendereinstellung/" + currentUser.photoURL);
      onValue(kaleinstellungref, (snapshot) => {
              
        if (snapshot.exists()) {
      //    console.log(snapshot.val())
          setKalMinDate(snapshot.val()['minDate'])
          setKalMaxDate(snapshot.val()['maxDate'])
          setKalKalcolor(snapshot.val()['colorHolidayBan'])
          setkalenderEvent({
            minDate: snapshot.val()['minDate'],
            maxDate: snapshot.val()['maxDate'],
            colorHolidayBan: snapshot.val()['colorHolidayBan']
          })
        
        }else{
         
          setKalMinDate(momentHolly(startOfYear(new Date())).format("YYYY-MM-DD"))
          setKalMaxDate(momentHolly(endOfYear(new Date())).format("YYYY-MM-DD"))
          setKalKalcolor('#ff0000')
          setkalenderEvent({
            minDate: momentHolly(startOfYear(new Date())).format("YYYY-MM-DD"),
            maxDate: momentHolly(endOfYear(new Date())).format("YYYY-MM-DD"),
            colorHolidayBan: '#ff0000'
          })
        
        
        }
      });  
  }

  const ModalKalenderEinstellungenEntered = () => {



    if (kalenderEvent) {
      KalMinDateref.current.value = kalenderEvent.minDate      
      KalMaxDateref.current.value = kalenderEvent.maxDate
      Kalcoloreref.current.value = kalenderEvent.colorHolidayBan

    }
  };

  const savekalenderEvent = () => {
    const updates = {};
    
    const updateData = {
        minDate: KalMinDate,
        maxDate: KalMaxDate,
        colorHolidayBan: Kalcolor,
    };
    updates["/kalendereinstellung/" + currentUser.photoURL] = updateData;
    update(ref(db), updates).then(() => {
      setkalenderEvent(updateData)
      setkaleinstellung(false);
    });
   
    
  }

const handleChangeUSpeere = () => {
    setUrlaubssperre((prev) => !prev);
}


  return (
    <>
      <React.Fragment>
        <Container fluid>
          <Form className="mt-3">
           
          <div className="row justify-content-around">
          <div className="col align-self-start">
                <Form.Check
                  type="switch"
                  id="-switch"
                  label="WE Anzeigen"
                  onChange={handleChange}
                />
             </div> 

             <div className="col align-self-center">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Wochenzahl Anzeigen"
                  onChange={handleChangeWeekNumber}
                />
               </div> 

              {isAdmin ? ( 
              
                <div className="col align-self-end">
                <Button                 
                  className="shadow col-auto ml-md-auto"
                  variant="outline-primary"                
                  onClick={() => {
                    setkaleinstellung(true)
                    }}
                >
                Kalendereinstellungen
                </Button>
               
                <Form.Check
                  reverse
                  ref={UrlaubssperreRef}
                  type="switch"
                  color="primary"
                  variant="outlined"
                  id="USSwitch"
                  label="Urlaubssperre"
                  value={Urlaubssperre}
                 // checked={!!Urlaubssperre}
                  onChange={handleChangeUSpeere}
                  //onChange={setUrlaubssperre(true)}
                />


                </div> 
                
             
              ):(<></>)}
          </div>
           
          </Form>

          <div className="row">
            <div
              className={
                isAdmin
                  ? "col-md-4 col-lg-4 col-xl-8 col-12 mt-3"
                  : "col-md-4 col-lg-4 col-xl-10 col-12 mt-3"
              }
            >
              <Calendar
                language="de"
                dataSource={mydata}
                disabledDays={DisabledDays}
                minDate={new Date(KalMinDate)}
                maxDate={new Date(KalMaxDate)}
                onYearChanged={(year) => handleononYearChanged(year)}
                displayWeekNumber={showWeeknumbers}
                enableRangeSelection={true}
                hiddenWeekDays={weekendarray}
                roundRangeLimits={true}
                allowOverlap={true}
                onDayEnter={(e) => handleDayEnter(e)}
                displayDisabledDataSource={true}
                enableContextMenu={true}
                contextMenuItems={contextmenu}
                onDayContextMenu={(date, events) =>
                  handleonDayContextMenu(date, events)
                }
                onRangeSelected={(e) =>
                  handleonRangeSelected(e.startDate, e.endDate)
                }
                customDayRenderer={(element, date) =>
                  handlecustomDayRenderer(element, date)
                }
                customDataSourceRenderer={(element, date, events) =>
                  handlecustomcustomDataRenderer(element, date, events)
                }
              ></Calendar>
            </div>
            <div
              className={
                isAdmin
                  ? "col-md-4 col-lg-4 col-xl-4 col-12 mt-3"
                  : "col-md-4 col-lg-4 col-xl-2 col-12 mt-3"
              }
            >
              {!isLoading ? (
                <UrlaubInfo
                  FID={FID}
                  year={currentYear}
                  urlaubsDatenYear={mydata}
                  childToParent={childToParent}
                  isAdmin={isAdmin}
                ></UrlaubInfo>
              ) : (
                <></>
              )}
            </div>
          </div>

          <Modal
            show={currentEvent}
            onHide={() => setCurrentEvent(null)}
            onEntering={() => ModalEntered()}
          >
            {currentEvent && (
              <div>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {currentEvent.id !== undefined
                      ? "Antrag Überarbeiten"
                      : "Neuer Antrag"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="mb-3 ">
                    <Form.Group as={Col} className="col-md-12">
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="col-4 shadow"
                          id="inputGroupPrepend"
                        >
                          Name:
                        </InputGroup.Text>

                        <Form.Control
                          as="select"
                          className="shadow"
                          disabled={currentEvent.id ? true : false}
                          onChange={(e) => {
                            useronChange(e.target.value);
                          }}
                        >
                          <option
                            className="d-none"
                            value={currentEvent.uid ? currentEvent.uid : ""}
                          >
                            {currentEvent.name
                              ? currentEvent.name
                              : "Mitarbeiter wählen"}
                          </option>
                          {optionsMitarbeiter.map((option) => (
                            <option value={option.value} key={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Control>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 ">
                    <Form.Group as={Col} className="col-md-12">
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="col-4 shadow"
                          id="inputGroupPrepend"
                        >
                          Anlass:
                        </InputGroup.Text>

                        <Form.Control
                          as="select"
                          className="shadow"
                          disabled={currentEvent.id ? true : false}
                          onChange={(e) => {
                            anlassonChange(e.target.value);
                          }}
                        >
                          <option
                            className="d-none"
                            value={
                              currentEvent.anlassid ? currentEvent.anlassid : ""
                            }
                          >
                            {currentEvent.anlass
                              ? currentEvent.anlass
                              : "Den Anlass wählen"}
                          </option>
                          {optionsAnlass.map((option) => (
                            <option value={option.value} key={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Control>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  {/* <Row className="mb-3 ">
                    <Form.Group
                      as={Col}
                      className="col-md-12"
                      id="validationAnlass"
                    >
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="col-4 shadow"
                          id="inputGroupPrepend"
                        >
                          Anlass:
                        </InputGroup.Text>
                        <Form.Control
                          id="event-anlass"
                          className="shadow"
                          type="text"
                          placeholder="Anlass"
                          aria-describedby="inputGroupPrepend"
                          required
                          value={currentEvent.anlass || ""}
                          onChange={(e) =>
                            setCurrentEvent({
                              ...currentEvent,
                              anlass: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>*/}
                  <Row className="mb-3 ">
                    <Form.Group
                      as={Col}
                      className="col-md-12"
                      id="validationmindate"
                    >
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="col-4 shadow"
                          id="inputGroupPrepend"
                        >
                          Datum von:
                        </InputGroup.Text>
                        <Form.Control
                          ref={startdayref}
                          max={endDate}
                          className="shadow"
                          id="min-date"
                          type="date"
                          aria-describedby="inputGroupPrepend"
                          required
                          /*  value={                           
                              momentHolly(currentEvent.startDate).format(
                              "YYYY-MM-DD"
                            )}*/
                          onChange={(e) => {
                            handleonRangeSelected(
                              e.target.valueAsDate,
                              currentEvent.endDate
                            );
                            //startdateonChange(e);
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 ">
                    <Form.Group
                      as={Col}
                      className="col-md-12"
                      id="validationmindate"
                    >
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="col-4 shadow"
                          id="inputGroupPrepend"
                        >
                          Datum bis:
                        </InputGroup.Text>
                        <Form.Control
                          ref={enddayref}
                          min={startDate}
                          className="shadow"
                          // max={new Date().toISOString().slice(0, 10)}
                          type="date"
                          aria-describedby="inputGroupPrepend"
                          required
                          onChange={(e) => {
                            handleonRangeSelected(
                              currentEvent.startDate,
                              e.target.valueAsDate
                            );
                            // enddateonChange(e);
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 ">
                    <Form.Group
                      as={Col}
                      className="col-md-12"
                      id="validationCustomName"
                    >
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="col-6 shadow"
                          id="inputGroupPrepend"
                        >
                          Anzahl Tage:
                        </InputGroup.Text>
                        <Form.Control
                          ref={AnzalTageRef}
                          type="text"
                          className="shadow"
                          aria-describedby="inputGroupPrepend"
                          required
                          //value={currentEvent.anzahltage || ""}
                          readOnly
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    variant="outline-secondary"
                    disabled={false}
                    className="shadow"
                    onClick={() => {
                      setModalPrimaryButtonDisabled(true);
                      setupdateUrlaubsTage(0);
                      setCurrentEvent(null);
                    }}
                  >
                    Abbrechen
                  </Button>
                  <Button
                    className="shadow"
                    variant="outline-primary"
                    disabled={ModalPrimaryButtonDisabled}
                    onClick={() => {
                      saveCurrentEvent();
                    }}
                  >
                    Speichern
                  </Button>
                </Modal.Footer>
              </div>
            )}
          </Modal>

          <Modal
            show={kaleinstellung}
            onHide={() => setkaleinstellung(false)}
            onEntering={() => ModalKalenderEinstellungenEntered()}
          >
          {kaleinstellung && (
              <div>
                <Modal.Header closeButton>
                  <Modal.Title>                   
                     Kalendereinstellungen                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <Row className="mb-3 ">
                    <Form.Group
                      as={Col}
                      className="col-md-12"
                      id="validationmindate"
                    >
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="col-4 shadow"
                          id="inputGroupPrepend"
                        >
                          Min Datum:
                        </InputGroup.Text>
                        <Form.Control
                          ref={KalMinDateref}
                        //  min={KalMinDate}
                          className="shadow"                         
                          type="date"
                          aria-describedby="inputGroupPrepend"
                          required
                          onChange={(e) => {
                           // setKalMinDate(e.target.valueAsDate)
                            setKalMinDate(momentHolly(e.target.valueAsDate).format("YYYY-MM-DD"));
                          /*  handleonRangeSelected(
                              currentEvent.startDate,
                              e.target.valueAsDate
                            );*/
                            // enddateonChange(e);
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3 ">
                    <Form.Group
                      as={Col}
                      className="col-md-12"
                      id="validationmindate"
                    >
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="col-4 shadow"
                          id="inputGroupPrepend"
                        >
                          Max Datum:
                        </InputGroup.Text>
                        <Form.Control
                          ref={KalMaxDateref}
                       //   min={KalMaxDate}
                          className="shadow"
                          // max={new Date().toISOString().slice(0, 10)}
                          type="date"
                          aria-describedby="inputGroupPrepend"
                          required
                          onChange={(e) => {
                            setKalMaxDate(momentHolly(e.target.valueAsDate).format("YYYY-MM-DD"));
                           // setKalMaxDate(e.target.valueAsDate)
                          /*  handleonRangeSelected(
                              currentEvent.startDate,
                              e.target.valueAsDate
                            );*/
                            // enddateonChange(e);
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3 ">
                    <Form.Group
                      as={Col}
                      className="col-md-12"
                      id="validationmindate"
                    >
                      <InputGroup hasValidation>
                        <InputGroup.Text
                          className="col-6 shadow"
                          id="inputGroupPrepend"
                        >
                          Farbe für Urlaubssperre:
                        </InputGroup.Text>
                        <Form.Control
                          ref={Kalcoloreref}                          
                          className="shadow"
                          // max={new Date().toISOString().slice(0, 10)}
                          type="color"
                          aria-describedby="inputGroupPrepend"
                          required
                          onChange={(e) => {
                              setKalKalcolor(e.target.value)
                          /*  handleonRangeSelected(
                              currentEvent.startDate,
                              e.target.valueAsDate
                            );*/
                            // enddateonChange(e);
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>


                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="outline-secondary"
                    disabled={false}
                    className="shadow"
                    onClick={() => {                     
                      setkaleinstellung(false)
                    }}
                  >
                    Abbrechen
                  </Button>
                  <Button
                    className="shadow"
                    variant="outline-primary"
                  //  disabled={ModalPrimaryButtonDisabled}
                    onClick={() => {
                      savekalenderEvent();
                    }}
                  >
                    Speichern
                  </Button>
                </Modal.Footer>
              </div>
            )}
          </Modal>
        </Container>
        <Cube customLoading={!isLoading} time={0} background="#40bfb2" />
      </React.Fragment>
    </>
  );
}
